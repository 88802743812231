html {
  overflow-x: hidden !important;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  position: absolute;
  overflow-x: hidden;
  --primary-background-color: #ed4d41;
  overflow: hidden;
}

::-webkit-scrollbar {
  display: none;
}

/* * {
	outline: solid 1px red;
} */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input#otp_entered_by_user {
  width: 90%;
  margin-left: 10px;
  height: 50px;
  text-align: center;
}

.navbar-light .navbar-toggler {
  color: #f8efef;
  border-color: rgba(252, 251, 251, 0.1);
}

.doMore {
  margin-top: 30px;
}

.headerLogo {
  /* position: absolute; */
  width: 140px;
  top: 12px;
  cursor: pointer;
}

.img-responsive {
  width: 35%;
  height: 35%;
}

.beingtheparentlogo {
  height: 48px;
  top: 14px;
  margin-left: 2%;
}

@font-face {
  font-family: bl-bold;
  src: url(../fonts/Geomanist-Bold-Webfont/geomanist-bold-webfont.woff);
}

@font-face {
  font-family: bl-medium;
  src: url(../fonts/Geomanist-Medium-Webfont/geomanist-medium-webfont.woff);
}

@font-face {
  font-family: bl-thin;
  src: url(../fonts/Geomanist-Thin-Webfont/geomanist-thin-webfont.woff);
}

@font-face {
  font-family: bl-regular;
  src: url(../fonts/Geomanist-Regular-Webfont/geomanist-regular-webfont.woff);
}

@font-face {
  font-family: bl-light;
  src: url(../fonts/Geomanist-Light-Webfont/geomanist-light-webfont.woff);
}

@font-face {
  font-family: bl-extralight;
  src: url(../fonts/Geomanist-ExtraLight-Webfont/geomanist-extralight-webfont.woff);
}

@font-face {
  font-family: bl-black;
  src: url(../fonts/Geomanist-Black-Webfont/geomanist-black-webfont.woff);
}

h1,
h2,
h3,
h4,
h5,
h6,
label {
  font-family: "bl-light";
}

p {
  font-family: "bl-regular";
}

h1 {
  font-family: bl-bold;
}

.text-center h2 {
  font-family: bl-bold;
}

.text-center h3 {
  font-family: bl-bold;
}

.text-left h3 {
  padding: 10px;
  font-size: 22px;
  font-family: bl-bold;
  font-weight: bold;
}

h-btn {
  border-radius: 12px;
}

h5 {
  font-family: bl-regular;
  font-weight: bold;
}

.nav-link {
  padding-left: 20px !important;
  padding-right: 50px !important;
}

.custom-btn {
  padding: 10px;
  width: 207px;
  height: 55px;
  border-radius: 12px;
}

.custom-btn:hover {
  background-color: #ed4d41 !important;
}

.brandImage {
  width: 90%;
  overflow: hidden;
  height: 107%;
}

.text-left p {
  padding: 20px;
  padding-left: 10px;
  padding-top: 0px;
}

.dotSlider {
  margin-top: 40px;
}

.lgn {
  background-color: #ff5a5f;
  border: none;
}

.text-left img {
  padding: 20px;
}

.container h2 {
  font-family: bl-bold;
}

.brandPageConatiner {
  margin-top: 40px;
}

.classRow {
  margin-top: 30px;
}

input,
label,
option,
select,
i,
li,
ol,
ul,
button,
bold,
strong,
a,
p {
  font-family: bl-regular;
  color: #333333;
}

.secondFotRow {
  margin-top: 52px !important;
}

.logo {
  position: absolute;
  width: 171px;
  height: 29px;
  left: 120px;
  top: 37px;
}

.logoLeft {
  width: 30%;
  float: left;
}

.buildLeft h2 {
  font-family: bl-regular;
}

.header {
  width: 100%;
  float: left;
  height: auto;
  font-family: bl-regular;
  font-weight: bold;
  height: auto;
  padding: 0;
  margin-top: 10px;
  color: black;
  padding-bottom: 15px;
  padding-top: 15px;
}

footer {
  background: #f9f9f9;
  padding: 25px;
  margin-top: 50px;
  overflow: hidden;
}

.solution {
  border-bottom: solid #f17a71 5px;
}

.btpLogo {
  width: 9rem;
  margin-bottom: 30px;
  margin-top: 30px;
}

.btpPara {
  width: 40%;
  margin-left: 30%;
  margin-top: 30px;
}

.advertising {
  width: 60%;
  margin-left: 20%;
}

.middle {
  margin-top: 30px;
}

.brandLeft {
  margin-top: -20px;
}

#towardLeft1 {
  margin-top: -20px;
}

.take-step-homepage-barndlabs {
  width: 100% !important;
  /* overflow: hidden; */
  /* float: left; */
}

.simple-tools-for-every {
  width: 100% !important;
  overflow: hidden;
  float: left;
  /* margin-top: 100px; */
}

ul {
  list-style: none;
}

.footer-col {
  width: 25%;
}

.footer-col h4 {
  font-size: 18px;
  color: #999999;
  margin-bottom: 30px;
  margin-left: 33px;
}

.footer-col h4::before {
  height: 2px;
  box-sizing: border-box;
  width: 50px;
  color: #999999;
}

.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}

.footer-col ul li a {
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
  font-weight: 300;
  color: #333333;
  display: block;
  transition: all 0.3s ease;
}

.footer-col ul li a:hover {
  color: #333333;
  padding-left: 8px;
}

.footer-col .social-links a {
  display: inline-block;
  height: 40px;
  width: 30px;
  background-color: rgba(255, 255, 255, 0.2);
  text-align: center;
  border-radius: 50%;
  color: #333333;
  transition: all 0.5s ease;
  margin-left: 30px;
}

#parentsLeft {
  margin-top: 100px;
}

#parentsLeft h1 {
  font-size: 54px;
}

#parentsLeft p {
  padding: 10px;
  padding-left: 0px;
  font-size: 22px;
}

#brandLeft {
  margin-top: 100px;
  float: left;
}

#preciseRight {
  margin-top: 100px;
}

#buildLeft {
  margin-top: 40px;
  margin-left: 25%;
}

.imgTag {
  background-image: url("../img/Group\ 2050.png");
  width: 80%;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-left: 10%;
  height: 100%;
  overflow: hidden;
}

.loginImage {
  width: 90%;
  overflow: hidden;
  margin-top: 20px;
}

.perfectPic {
  width: 40%;
  height: 40%;
  overflow: hidden;
}

#towardLeft {
  width: 100%;
  height: 400px;
  background-color: #fef8dc;
}

.towardLeft1 {
  margin-top: 100px;
}

.circleBuild {
  width: 200px;
  height: 200px;
  background-color: #fff0fe;
  overflow: visible;
  margin-top: 100px;
  border-radius: 100%;
}

.circleBuild1 {
  position: absolute;
  color: #24262b;
  padding: 50px;
}

.dot {
  width: 18px;
  height: 18px;
  background-color: #ed4d41;
  border-radius: 50%;
  display: inline-block;
  padding: 5px;
  justify-content: space-between;
  margin-left: 1px;
}

.dot1 {
  width: 18px;
  height: 18px;
  background-color: #f0f0f0;
  border-radius: 50%;
  display: inline-block;
  justify-content: space-between;
  padding: 5px;
}

.vectorLogo {
  width: 5%;
  margin-top: 24px;
  margin-bottom: 2%;
}

.logoFooter {
  width: 100px;
}

.logoFooter1 {
  width: 100px;
  margin-left: 106px;
  margin-top: -58px;
}

.buildRelationship {
  margin-left: 10px;
}

.footerConatct {
  margin-left: 10px;
}

.imgParents {
  margin-left: 10%;
  width: 80%;
  height: 350px;
  background-repeat: no-repeat;
  background-size: 100%;
}

#toolkitImg {
  margin-right: 100px;
}

.toolkitH3 {
  margin-left: 100px;
  margin-top: 07px;
}

.toolkitP {
  margin-left: 140px;
}

.toolkitP1 {
  margin-left: 48px;
  margin-top: -20px;
}

#toolkitImgMiddle {
  margin-right: 220px;
}

.toolkitMiddleH3 {
  margin-right: 05px;
  margin-top: 07px;
}

.middleToolkitP {
  margin-right: 20px;
}

.middleToolkitp1 {
  margin-right: 145px;
  margin-top: -20px;
}

.preciseUl {
  list-style: square;
  color: black;
}

.mobile {
  display: none;
}

.pc {
  display: block;
}

.btnLogin {
  align-items: center;
  gap: 7.32px;
  border: none;
  margin-top: 8px;
  width: 110px;
  height: 50px;
  background: #ff5a5f;
  border-radius: 12px;
  font-family: bl-regular !important;
  font-weight: bold;
}

.copyrightFt {
  margin-top: 50px !important;
  margin-bottom: -10px !important;
  opacity: 60%;
}

.imgCeo {
  border-radius: 50%;
  width: 106px;
  height: 107px;
  overflow: hidden;
}

.navbar-toggler-icon {
  color: #333333;
}

.preciseCircle {
  width: 455px;
  height: 455px;
  border-radius: 50%;
  background: #e8fff2;
  text-align: left;
}

.parentsDoBold {
  color: #333333;
  font-family: bl-medium;
  font-size: 48px !important;
}

.brandColor {
  color: #ff5a5f;
  font-weight: bold;
  font-size: 16px;
  line-height: 32px;
}

.half-circle {
  width: 156px;
  height: 398px;
  background-color: #e8fff2;
  border-bottom-right-radius: 129px;
  border-top-right-radius: 129px;
  border-left: 0;
  box-sizing: border-box;
  margin-bottom: -362px;
  margin-left: -12px;
  margin-top: 33px;
}

.dabarPic {
  margin-top: -106px;
  width: 90%;
  overflow: hidden;
}

.wantsToTalk {
  width: 100%;
  background-color: #f26358;
  height: 341px;
  margin-left: auto;
  border-radius: 25px;
  box-shadow: 0.01rem 0.1rem 0.3rem;
  margin-top: 40px;
  padding: 23px;
  overflow: hidden;
  margin-bottom: 100px !important;
}

.parentWantPic {
  margin-top: -21%;
  margin-left: -4%;
  width: 105%;
  position: absolute;
  bottom: 0;
}

.pages {
  margin-left: 10% !important;
}

.parentsHeading {
  color: #f0f0f0;
  margin-top: 120px;
  margin-left: 30px;
  font-size: 28px;
}

.btnAdvertise {
  margin-left: 30px;
  font-size: 28px;
  background: #f0f0f0;
  color: #ed4d41;
  font-weight: 300;
  font-size: 22px;
  font-family: bl-regular;
  padding: 10px;
}

.brandLeftPara {
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #333333;
  font-family: bl-regular;
  font-weight: 500 !important;
  width: 71%;
}

.card1 {
  background: #ffffff;
  box-shadow: 0px 1px 34px rgba(136, 136, 136, 0.1);
  border-radius: 25px;
  height: 250px;
  overflow: hidden;
  font-family: bl-regular;
}

.card2 {
  background: #ffffff;
  box-shadow: 0px 1px 34px rgba(136, 136, 136, 0.1);
  border-radius: 25px;
  height: 250px;
  overflow: hidden;
  font-family: bl-regular;
}

.containerBuild p {
  text-align: left;
  width: 400px;
  font-family: bl-regular;
}

@media (max-width: 767px) {
  .footer-col {
    width: 50%;
    margin-bottom: 30px;
    overflow: hidden;
  }
}

@media (max-width: 574px) {
  .footer-col {
    width: 100%;
    overflow: hidden;
  }
}

.linkPages {
  text-decoration: none;
  font-weight: 300;
  font-size: 18px;
  line-height: 46px;
  color: #666666;
  font-family: bl-regular;
}

.linkPages:hover {
  color: #333333;
}

@media only screen and (max-width: 360px) {
  .btnLogin {
    margin-left: 117% !important;
    height: 37px;
    text-align: center;
    background-color: #ff5a5f;
    border-radius: 10px;
    overflow: hidden;
    border: none;
    font-family: bl-regular;
    margin-top: 2px;
    border: none !important;
    width: 90px !important;
    color: #e8fff2;
  }

  .parentWantPic {
    margin-top: -48% !important;
    margin-left: -25% !important;
    width: 139%;
    height: 100%;
    padding: 6% !important;
    overflow: hidden;
  }
}

@media (max-width: 1100px) {
  .parentsDoBold h1 {
    font-family: bl-thin !important;
  }

  .headerLogo {
    position: absolute;
    width: 140px;
    top: 30px;
  }

  .pages {
    margin-left: 8% !important;
  }

  .secondFotRow {
    margin-top: -47px !important;
  }

  .doMore {
    margin-top: 30px;
    margin-left: -40%;
  }

  body {
    width: 100%;
    margin: 0px;
    padding: 0px;
  }

  .imgCeo {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    overflow: hidden;
  }

  .card1 {
    margin-top: 13px;
    overflow: hidden;
    float: left;
    margin-top: 13px;
    overflow: hidden;
    float: left;
  }

  .card2 {
    margin-top: 10px;
  }

  .yourPer {
    width: 100%;
  }

  .imgTag {
    width: 85%;
    overflow: hidden;
    background-repeat: no-repeat;
    text-align: center;
    margin-left: 10%;
    background-image: url("../img/Group\ 2122.png");
    height: 100%;
    padding: 20px;
    margin-bottom: -10px;
  }

  .goals {
    width: 100% !important;
    background-color: #fef8dc !important;
  }

  .goals container {
    padding: 0;
  }

  #towardLeft {
    width: 100%;
    height: 700px;
    background-color: #fef8dc;
  }

  #preciseRight {
    margin-top: -20px;
  }

  .leftRes {
    text-align: left;
    margin-left: -20%;
    font-family: bl-regular;
    font-weight: 300;
    font-size: 32px;
    width: 95%;
  }

  .leftRes1 {
    margin-left: -20%;
    text-align: left;
  }

  .leftGoals {
    text-align: left;
    margin-left: -20%;
    font-family: bl-regular;
    font-weight: 300;
    font-size: 32px;
    line-height: 150%;
    width: 300px;
    overflow: hidden;
  }

  .leftGoals1 {
    margin-left: -74%;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    overflow: hidden;
  }

  .containerDoMore {
    margin-left: -4%;
    text-align: left;
    width: 100%;
  }

  .img-responsive {
    display: none;
    overflow: hidden;
  }

  .imgParents {
    width: 80%;
    height: 200px;
    background-repeat: no-repeat;
    background-size: 100%;
    overflow: hidden;
  }

  .btnLogin {
    margin-left: 117% !important;
    height: 40px;
    text-align: center;
    background-color: #ff5a5f;
    border-radius: 10px;
    overflow: hidden;
    font-family: bl-regular;
    border: none;
    margin-top: 2px;
    border: none !important;
    padding: 16px 86px !important;
    width: 95px;
    color: #e8fff2;
    font-weight: 400;
  }

  .navbar-toggler {
    border: none !important;
    color: transparent;
    overflow: hidden;
  }

  .logoLeft img {
    width: 34%;
    float: left;
    margin-top: -2px;
    overflow: hidden;
  }

  .circleBuild1 {
    float: left;
    position: absolute;
    color: #24262b;
    text-align: left;
    overflow: hidden;
  }

  .circleBuild {
    width: 200px;
    height: 200px;
    background-color: #fff0fe;
    overflow: visible;
    margin-top: 10px;
    border-radius: 100%;
    margin-left: -55px;
    overflow: hidden;
  }

  .familyImage {
    margin-top: 35px;
    margin-left: 3px;
    overflow: hidden !important;
    width: 100% !important;
  }

  .reg {
    width: 99%;
  }

  .reg1 {
    width: 99%;
    margin-top: 10px;
    background-color: #ed4d41;
  }

  .loginImageMobile {
    width: 90%;
    overflow: hidden;
    padding: 2px;
    margin-top: 5px;
    margin-left: 10px;
  }

  .parentsDoPlay {
    margin-top: -50px;
  }

  p.btpPara {
    text-align: center;
    font-size: 14px;
    line-height: 1.8rem;
    color: #333333;
  }

  .btpPara {
    text-align: left;
    width: 100%;
    margin-left: 0px;
  }

  .perfectPic {
    width: 100% !important;
    margin-left: -20px !important;
  }

  .middle {
    width: 100%;
    margin-left: -19%;
    font-size: 16px;
    margin-top: 70px;
    color: #333333;
  }

  .advertising {
    text-align: left;
    width: 100%;
    font-family: bl-regular;
    color: #282828;
    margin-left: 4%;
    line-height: 150%;
    letter-spacing: 0.04em;
  }

  #brandLeft h2 {
    font-size: 28px !important;
  }

  #brandLeftPara p {
    font-size: 14px;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: #333333;
    font-family: bl-regular;
    font-weight: 400 !important;
  }

  #brandLeft {
    margin-top: 20px;
  }

  .circleBuild {
    width: 200px;
    height: 200px;
    background-color: #fff0;
    overflow: visible;
    margin-top: 23px;
    border-radius: 100%;
    margin-left: -55px;
  }

  .circleBuildMobile {
    width: 172px;
    height: 172px;
    overflow: hidden;
    background: #e7d3e5;
    border-bottom-left-radius: 100px;
    border-top-left-radius: 100px;
    border-left: 0;
    box-sizing: border-box;
    margin-bottom: -410px;
    margin-left: 269px;
    margin-top: 1px;
  }

  .dot {
    margin-top: 10px;
  }

  .half-circle {
    width: 104px;
    height: 296px;
    background-color: #e8fff2;
    border-bottom-right-radius: 100px;
    border-top-right-radius: 100px;
    border-left: 0;
    box-sizing: border-box;
    margin-bottom: -267px;
    margin-left: 12px;
  }

  .dabarPic {
    margin-top: -13%;
    width: 90%;
    overflow: hidden;
    margin-left: 13%;
  }

  .footer-col ul li a {
    float: left;
    color: #ffffff;
    text-decoration: none;
    color: #333333;
    padding: 15px;
    transition: all 0.3s ease;
    margin-left: -20px;
  }

  .parentsHeading {
    color: #f0f0f0;
    margin-top: 20px;
    margin-left: -2px;
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    font-family: bl-regular;
  }

  .btnAdvertise {
    margin-left: -2px;
    font-size: 28px;
    background: #f0f0f0;
    color: #ed4d41;
    font-weight: 300;
    font-size: 22px;
    font-family: bl-regular;
    padding: auto;
    border-radius: 10px;
  }

  .logoFooter1 {
    width: 100px;
    margin-left: -217px;
    margin-top: 11px;
  }

  .logoFooter {
    width: 100px;
    margin-top: 10px;
    margin-left: -20px;
  }

  .download {
    color: #333333 !important;
    margin-left: -18px;
    float: left;
    margin-top: -20px;
  }

  .downloadOur {
    margin-left: -1%;
  }

  .vectorLogo {
    width: 40px;
  }

  .copyrightFt {
    opacity: 60%;
    padding: 3px;
    margin-left: 5%;
  }

  .containerBuild {
    margin-top: -16%;
  }

  .buildCircle {
    margin-top: -16%;
  }

  .hrm {
    width: 700px;
    margin-left: -200px !important;
    overflow: hidden;
  }

  .card.border-0.wantsToTalk {
    overflow: hidden;
  }

  .wantsToTalk {
    background-color: #f26358;
    height: 280px;
    width: 95%;
    margin-right: 3%;
    padding: 2%;

    overflow: hidden;
  }

  .parentWantPic {
    margin-top: -53%;
    margin-left: -23%;
    width: 139%;
    height: 100%;
    padding: 2%;
    overflow: hidden;
  }
}

.familyImage {
  width: 90%;
  overflow: hidden;
}

@media (max-width: 1100px) {
  body {
    padding: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .navbar-toggler.collapsed:focus {
    border: none !important;
  }

  .navbar-toggler:focus-visible {
    border: none !important;
  }

  .mobile {
    display: block;
    padding: 0px !important;
    margin-left: -20px;
  }

  .pc {
    display: none;
  }

  .parentsDoBold {
    font-size: 32px !important;
  }

  .parentsDoBold h1 {
    font-family: bl-light !important;
  }

  .parentsDoPlay p {
    font-size: 14px !important;
    font-family: bl-regular;
  }

  .btn.btn-outline-danger.btn-lg.custom-btn.reg {
    padding: 0px !important;
    height: 45px;
    border: solid 2px;
    font-family: bl-regular;
    font-size: 16px;
  }

  .btn.btn-danger.btn-lg.custom-btn.lgn.reg1 {
    padding: 0px;
    height: 45px;
    font-family: bl-regular;
    font-size: 16px;
  }

  .container.text-center.yourPerfect {
    margin-top: 40px;
  }

  img.btpLogo {
    width: 30%;
    margin-bottom: 40px;
    overflow: hidden;
  }
}

@media (max-width: 320px) {
  .btnLogin {
    margin-left: 96% !important;
  }

  .parentWantPic {
    margin-top: -51% !important;
    width: 149%;
    height: 100%;
    overflow: hidden;
    margin-left: -31% !important;
  }
}

h2#head {
  text-align: center;
  padding: 10px;
}

p#pead {
  padding: 20px;
  text-align: center;
}

.circle {
  width: 100px;
  height: 100px;
  padding: 10px;
  background-color: white;
  overflow: hidden;
  text-align: center;
  float: left;
  border-radius: 100%;
}

.line {
  width: 100px;
  overflow: hidden;
  height: 1px;
  margin-left: -4px;
  margin-top: 52px;
  border: solid rgba(244, 182, 25, 0.25) 1px;
  float: left;
  z-index: -10;
}

.right-line {
  float: left;
  width: 20px;
  height: 20px;
  background-color: white;
  margin-top: 44px;
  margin-left: -14px;
  overflow: hidden;
  z-index: -10;
  transform: rotate(45deg);
}

.takeSteps {
  margin-top: 40px;
}

p#on-p {
  float: left;
  margin-top: 40px;
  overflow: visible;
  position: absolute;
  text-align: center;
  font-weight: 900;
  width: 150px;
  height: 60px;
  margin-left: -36px;
  font-family: bl-regular;
}

@media (max-width: 1100px) {
  .onboarding {
    float: left;
    margin-left: -18%;
    background-color: #fef8dc;
    padding: 28%;
    margin-top: -24%;
  }

  .circle {
    width: 44%;
    height: 44%;
    padding: 7%;
    background-color: white;
    overflow: hidden;
    text-align: center;
    float: left;
    border-radius: 100%;
  }

  .line {
    width: 100px;
    height: 100px;
    margin-left: 22%;
    margin-top: 0px;
    border: none;
    border-left: solid rgba(244, 182, 25, 0.25) 2px;
    float: left;
    z-index: -10;
  }

  .right-line {
    width: 20px;
    height: 20px;
    background-color: white;
    margin-top: 85px;
    margin-left: -26%;
    overflow: hidden;
    transform: rotate(36deg);
  }

  p#on-p {
    float: left;
    margin-top: -12%;
    overflow: visible;
    position: absolute;
    text-align: center;
    font-weight: 900;
    margin-left: 30%;
  }
}

.onboarding {
  margin-left: 10%;
  float: left;
  width: 100%;
}

button.add-section-btn {
  margin-bottom: 50px;
}

.brand-pages-container {
  width: 100%;
  float: left;
  overflow: hidden;
}

.brand-page-main-section {
  background: linear-gradient(180deg, #e9392c 0%, #ee564a 33.09%);
  text-align: center;
}

.brand-page-section {
  height: "100%";
  padding: 50px;
  text-align: center;
  width: 60%;
  margin-left: 20%;
  overflow: hidden;
  padding-bottom: 0px;
}

.product-page-section {
  height: "100%";
  padding: 50px;
  text-align: center !important;
  width: 60%;
  overflow: hidden;
  padding-bottom: 0px;
}

.product-page-section h1 {
  font-family: bl-medium;
  color: white;
  font-size: 58px;
  margin-top: 20px;
}

.product-page-section p {
  font-size: 24px;
  color: white;
  margin-top: 10px;
  width: 60%;
  text-align: center;
  margin-left: 20%;
}

.product-page-section button {
  height: 65px;
  background: #ffffff;
  border: none;
  font-size: 20px;
  color: #ff5e3a;
  border-radius: 12px;
  box-shadow: 0px 1px 34px rgba(136, 136, 136, 0.1);
  font-family: "bl-bold";
  margin-top: 100px;
  text-align: center;
  margin-left: 40%;
  width: 20%;
  margin-top: 0px;
}

.brand-page-section-third {
  height: "100%";
  padding: 50px;
  text-align: center;
  width: 60%;
  margin-left: 20%;
  overflow: hidden;
  padding-bottom: 0px;
}

.brand-page-section-third h1 {
  font-family: bl-medium;
  color: #ff5e3a !important;
  font-size: 58px;
  overflow: hidden;
}

.brand-page-section-third p {
  font-size: 24px;
  color: white;
  margin-top: -40px;
}

.brand-page-section-third button {
  width: 200px;
  padding: 18px;
  border: none;
  font-family: bl-medium;
  border-radius: 14px;
  background: linear-gradient(0deg, #ff2a68 0%, #ff5e3a 100%);
  padding: 16px 36px;
  gap: 12px;
  color: white;
  box-shadow: 0px 0px 10px 25px (#000000);
}

.brand-page-section h1 {
  font-family: bl-medium;
  color: white;
  font-size: 58px;
  margin-top: 20px;
}

.brand-page-section p {
  font-size: 24px;
  color: white;
}

.brand-page-section button {
  width: 40%;
  padding: 18px;
  border: none;
  font-family: bl-medium;
  border-radius: 14px;
  margin-left: 30%;
  box-shadow: 0px 0px 10px 25px (#000000);
}

.brand-page-section-third img {
  width: 100%;
}

.booo {
  margin-top: 100px;
}

.brand-page-section img {
  width: 100%;
  margin-top: 100px;
  margin-bottom: -25px;
  margin-top: 145px;
  overflow: hidden;
}

.product-page-second-section {
  background-color: white;
}

.product-page-second-section h1 {
  color: #333333;
  padding: 50px;
  font-size: 50px;
}

.product-page-second-section p {
  color: #333333;
}

.brand-page-second-section {
  background-color: white;
  color: #333333;
}

.brand-page-second-section h1 {
  color: #333333;
  margin-top: 60px;
  margin-bottom: 40px;
}

.brand-page-second-section p {
  color: #666666;
}

.full-width {
  width: 100%;
  float: left;
  padding: 50px;
  background-color: #ffffff;
  margin-top: 200px;
  height: 100%;
}

.full-width-question {
  width: 100%;
  float: left;
  padding: 50px;
  background-color: #ffffff;
  height: 100%;
}

.inner-full-width {
  width: 100%;
  float: left;

  overflow: hidden;
}

.left-width {
  width: 60%;
  float: left;
  margin-top: 160px;
}

.left-width-discovered {
  width: 60%;
  float: left;
}

.left-width-customer {
  width: 60%;
  float: left;
}

#normal {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: #333333;
  flex: 100%;
  font-family: bl-regular;
}

.left-width p {
  font-family: "bl-regular";
  font-style: normal;
  width: 100%;
  flex: 100%;
  font-weight: 400;
  font-size: 36px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: #999999;
  margin-top: 20px;
  width: 80%;
}

.left-width h2 {
  margin-top: 20px;
}

.right-width {
  width: 40%;
  float: left;
}

.right-width-customer {
  width: 40%;
  float: left;
}

.right-width-discovered {
  width: 40%;
  float: left;
}

.inner-left-customer {
  border: none;
  border-radius: 48px;
  padding: 33px;
  background: #ffffff;
  box-shadow: 0px 1px 34px rgba(136, 136, 136, 0.1);
  margin-right: 5%;
  overflow: hidden;
}

.inner-right-width-discovered {
  border: none;
  border-radius: 48px;
  padding: 33px;
  background: #ffffff;
  box-shadow: 0px 1px 34px rgba(136, 136, 136, 0.1);
  margin-right: 5%;
}

.inner-right-width {
  width: 70%;
  float: right;
  overflow: hidden;
  margin-top: 0px;
  height: 140px;
}

img#section-five-banner {
  width: 90%;
  margin-left: 5%;
}

.inner-left-width {
  float: left;
  overflow: hidden;
  width: 60%;
  height: 150px;
}

.inner-left-width img {
  width: 45px;
  height: 45px;
  float: left;
  margin-top: 100px;
}

.inner-left-width p {
  width: 70%;
  background: #ffffff;
  border-radius: 25px;
  border: 2px solid #f0f0f0;
  padding: 10px;
  margin-left: 60px;
  font-family: bl-regular;
  color: #333333;
  line-height: 130%;
  padding-left: 20px;
  margin-top: 60px;
}

.inner-right-width p {
  width: 70%;
  background: #f0f0f0;
  border-radius: 25px;
  border: 2px solid #f0f0f0;
  padding: 10px;
  margin-left: 70px;
  font-family: bl-regular;
  color: #333333;
  line-height: 130%;
  padding-left: 20px;
}

.inner-title {
  font-family: bl-bold;
  font-size: 40px;
}

.inner-title-review {
  font-family: bl-bold;
  font-size: 40px;
  width: 100%;
}

.inner-right-width img {
  float: right;
  margin-top: -40px;
  width: 45px;
  height: 45px;
  border: 1.92812px solid #f0f0f0;
  border-radius: 100%;
}

.inner-left-discovered-review {
  height: auto;
  background: #ffffff;
  backdrop-filter: blur(110px);
  border-radius: 48px;
  border: none;
  margin-left: 10%;
  padding: 23px;
  width: 80%;
  overflow: hidden;
  box-shadow: 0px 1px 34px rgba(136, 136, 136, 0.1);
}

.inner-left-discovered {
  height: auto;
  background: linear-gradient(
    180deg,
    rgba(153, 212, 234, 0.8) 0%,
    rgba(255, 255, 255, 0.8) 42.71%,
    rgb(243, 36, 36) 100%
  );
  backdrop-filter: blur(110px);
  border-radius: 48px;
  border: none;
  margin-left: 10%;
  padding: 23px;
  width: 80%;
  overflow: hidden;
}

.inner-left-discovered img {
  width: 100%;
  margin-bottom: -15%;
  margin-left: -11%;
}

.upload-text {
  font-size: 30px;
  font-family: bl-regular;
}

.inner-width-collapse {
  margin-left: 8%;
}

.inner-width-collapse h2 {
  color: #333333;
  font-size: 48px;
  font-family: bl-bold;
  margin-bottom: 100px;
}

.inner-width-collapse li {
  font-size: 24px;
  font-family: bl-bold;
  margin: 10px;
  width: 600px;
}

.inner-width-collapse ol {
  margin: 20px;
}

#example-collapse-text {
  font-size: 16px;
  color: #333333;
  font-family: bl-regular;
}

.inner-width-collapse span {
  display: flex;
}

.btn-collapse {
  margin-left: 10%;
  background-color: transparent;
  color: #333333;
  font-size: 30px;
  border: none;
}

button.btn-collapse[aria-expanded="true"] .plus {
  display: none;
}

button.btn-collapse[aria-expanded="false"] .close {
  display: none;
}

#slideset3 img {
  width: 620px;
  height: 620px;
}

.brand-page-getstarted-section {
  background: linear-gradient(
    91.44deg,
    #f04336 0%,
    rgba(240, 77, 65, 0.92) 86.41%
  );
  border-radius: 25px;
  width: 80%;
  margin-left: 10%;
  margin-top: 200px;
  float: left;
  margin-bottom: 100px;
  overflow: hidden;
}

.product-page-getstarted-section {
  background: linear-gradient(
    91.44deg,
    #f04336 0%,
    rgba(240, 77, 65, 0.92) 86.41%
  );
  border-radius: 25px;
  width: 80%;
  margin-left: 10%;
  margin-top: 200px;
  float: left;
  margin-bottom: 100px;
  overflow: hidden;
}

.inner-img-caursol {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  animation-name: example;
  animation-duration: 2s;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  visibility: hidden;
  background-repeat: no-repeat;
}

.carousel-indicators li {
  display: none;
}

.carousel-indicators {
  display: none !important;
}

.inner-width-collapse svg {
  position: relative;
}

.footer {
  background: #f9f9f9;
  padding: 25px;
  margin-top: 0px;
  float: left;
  width: 100%;
  margin-bottom: -50px;
}

.main-first-container {
  background: linear-gradient(180deg, #e9392c 0%, #ee564a 33.09%);
  text-align: center;
}

.get-started-btn {
  float: left;
  width: 300px;
  overflow: hidden;
  margin-bottom: 100px;
}

.get-started-btn button {
  width: 200px;
  float: left;
  border: soild #ffffff 2px;
  margin-left: 0px;
}

button#btn-one {
  float: left;
  background-color: transparent;
  color: white;
  margin-left: 50px;
  border: solid #ffffff 2px;
}

button#btn-two {
  background-color: #ffffff;
  position: absolute;
  float: left;
  border: solid #ffffff 2px;
}

.right-width img {
  position: absolute;
  width: 640px;
  z-index: 9;
}

/* Solution page */
.solution-page-conatiner {
  width: 100%;
}

.solution-full-width {
  width: 100%;
  float: left;
  padding: 80px;
  background: #fff6f5;
}

.solution-left-width {
  width: 40%;
  float: left;
}

.solution-right-width {
  width: 60%;
  float: left;
}

solution-right-image img {
  width: 100%;
}

.solution-first-left-div p {
  width: 458px;
  height: 280px;
  font-family: "bl-medium";
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 4rem;
  letter-spacing: 0.02em;
  margin-top: 70px;
}

.solution-first-left-div button {
  padding: 16px 86px;
  width: 260px;
  height: 65px;
  background: #ed4d41;
  box-shadow: 0px 1px 34px rgba(136, 136, 136, 0.1);
  border-radius: 12px;
  color: #ffffff;
  font-family: "bl-medium";
  border: none;
  margin-top: 20px;
}

.solution-first-left-div span {
  color: #ed4d41;
  font-family: "bl-medium";
}

.second-inner-div {
  margin-top: 70px;
  background: linear-gradient(180deg, #ed4d41 0%, #ff9635 70.1%, #ff9635 100%);
  border-radius: 24px;
  width: 100%;
  height: auto;
  padding: 30px;
  text-align: center;
}

.second-inner-div h1 {
  color: #ffffff;
  font-family: "bl-bold";
}

.second-inner-div p {
  font-family: bl-regular;
}

.introducing-card {
  display: flex;
  padding: 10px;
  margin-left: 20px;
  text-align: center;
  margin-left: 7%;
}

.solution-card {
  color: #333333;
  font-family: bl-regular;
  height: 340px;
  text-align: center;
  border-radius: 33px;
  box-shadow: 0px 5px 31px rgba(49, 49, 49, 0.1);
  width: 100% !important;
}

.solution-card img {
  width: 120px;
  height: 120px;
  text-align: center;
  margin-left: 30%;
  margin-top: 10%;
}

.solution-card p {
  font-family: bl-regular;
  font-size: 20px;
}

.solution-card h3 {
  color: #333333;
  font-family: "bl-medium";
}

#p-comp {
  color: #ffffff !important;
  font-size: 14px;
  font-family: bl-regular;
}

.our-solution-div {
  margin-top: 40px;
  width: 100%;
}

.our-solution-div h1 {
  font-family: bl-medium;
}

.left-width-solution {
  width: 60%;
  float: left;
  margin-top: 80px;
}

.right-width-solution {
  width: 40%;
  float: left;
  margin-top: 100px;
}

.boost-brand {
  margin-top: 30px;
}

.boost-inner-card {
  background: #ffffff;
  box-shadow: 0px 5px 31px rgba(49, 49, 49, 0.1);
  border-radius: 36px;
  border: none;
  padding: 30px;
}

.boost-inner-card1 {
  background: #ffffff;
  box-shadow: 0px 5px 31px rgba(49, 49, 49, 0.1);
  border-radius: 36px;
  border: none;
  padding: 30px;
  margin-top: 10px;
}

.right-width-solution span {
  color: #ed4d41;
}

img.right-header-img {
  max-height: 150px;
  border-radius: 10px;
  object-fit: cover;
}

nav.header.navbar.navbar-expand-lg.navbar-light {
  position: fixed;
  top: 0;
  background: white;
  margin-top: 0px;
  float: left;
  width: 100%;
  z-index: 999;
  border-bottom: 2px solid #eee;
}

div#top-gap {
  margin-top: 80px;
}

/* container 100% width temp if screen breaks remove this css */
@media (min-width: 1400px) {
  .container {
    max-width: 90%;
  }
}

.circle img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 15px;
}

#copyright {
  margin-bottom: 50px;
  margin-top: 50px;
  font-size: 16px;
}

#product-bg {
  width: 100% !important;
  margin-top: 40px;
}

.full {
  width: 100%;
  margin-left: 0%;
}

.review-text {
  text-align: center !important;
  color: #666666;
}

.review-text label {
  width: 100%;
  height: 96px;
  text-align: center;
  font-size: 60px;
  color: #666666;
  font-family: "bl-medium";
}

.review-text p {
  font-size: 28px;
  color: #666666;
  font-family: "bl-regular";
}

.brand-sec-img-inner {
  width: 300px;
  height: 300px;
  position: absolute;
  float: right;
  margin-top: -800px;
}

.product-page {
  overflow: hidden;
}

.brand-sec-img-inner img {
  width: 100%;
  height: auto;
}

#btn-badge {
  padding: 16px 48px;
  gap: 12px;
  width: 228.74px;
  height: 65px;
  background: #ffffff;
  box-shadow: 0px 1px 34px rgba(136, 136, 136, 0.1);
  border-radius: 12px;
  text-align: center;
}

#king_badge {
  width: 20px;
  height: 20px;
  margin-right: 20px !important;
  text-align: center;
}

.product-page-section-second {
  width: 100%;
  text-align: center;
}

.product-page-section-second h1 {
  font-size: 58px;
  color: #333333;
  font-family: "bl-medium";
  margin-top: 20px;
}

.product-page-section-second p {
  width: 60%;
  font-size: 24px;
  color: #666666;
  margin-left: 20%;
  margin-top: -10px;
  font-family: "bl-regular";
}

.build-product-bg {
  margin-top: -17% !important;
}

.product-page-second-section-do {
  width: 100%;
  text-align: center;
}

.product-do-more-section {
  margin-top: 12% !important;
  width: 100%;
}

.product-do-more-section h1 {
  font-family: "bl-medium";
  font-size: 58px;
  width: 100%;
}

.product-do-more-section p {
  font-size: 24px;
  color: #666666;
  padding: 20px;
  text-align: center;
  font-family: "bl-regular";
  width: 60%;
  margin-left: 20%;
}

.react-stars-wrapper-012194627292277604 {
  text-align: center;
  margin-left: 50% !important;
  /* margin-left: -100px */
}

.gather {
  font-size: 26px;
  width: 95%;
  font-family: "bl-regular";
  color: #333333;
  text-align: left;
  overflow: hidden;
  padding-right: 20px;
}
.undefined.react-stars {
  width: 100%;
  float: left;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 0px;
}

button#create-own-page {
  color: #ed4d41;
  position: absolute;
  float: left;
  border: solid #ffffff 2px;
  border: none;
}
.qu_and_ans img {
  margin-left: 60%;
  margin-top: -30px;
}
.have-any-question {
  background: #f9f9f9;
}

.tools-for-everyone {
  text-align: center;
  margin-top: 10%;
}

.download-img {
  display: flex;
  float: left;
  margin-left: 0%;
}

.download-img img {
  width: 100px;
}

/* image zoom */
.hero-section {
  width: 100%;
  height: 70rem;
  overflow: hidden;
  position: relative;
}

.hero-section img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

.animated_image {
  margin-top: 40px;
}

.animated_image img {
  margin-top: 100px;
  margin-bottom: -30px;
  height: auto;
}

.slide_brand_inner {
  background-color: #e9392c !important;
  width: 700px;
  height: 660px;
  border-radius: 23px;
  width: 160%;
}

.react-tel-input .form-control {
  position: relative !important;
  font-size: 14px !important;
  letter-spacing: 0.01rem !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px !important;
  margin-left: 0 !important;
  background: #ffffff !important;
  border: 1px solid #cacaca !important;
  border-radius: 5px !important;
  line-height: 25px !important;
  height: 47px !important;
  width: 100% !important;
  outline: none;
}

.otp {
  display: flex;
  justify-content: left;
  margin-top: 20px;
  width: 100%;
  align-items: center;
  margin-left: 22px;
}
.otp div {
  margin-left: -10px;
}

.otp_verify {
  display: flex;
  /* justify-content: left; */
  margin-top: 20px;
  width: 100%;
  align-items: center;
  /* margin-left: 22px; */
  margin-bottom: 10px;
}

.otp_verify div {
  margin: auto;
  /* margin-left: -10px; */
}

.inputStyle {
  width: 64px !important;
  height: 64px;
  border-radius: 7px;
  border: 0px;
  margin-left: 8px;
  margin-right: 8px;
  background: #fcfcfc;
  font-size: 20px;
  border: 1px solid #afafaf;
}
