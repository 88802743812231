.button-position{
    margin-top: 35px;
}
.text-alignment{
    text-align: justify;
}
.align-text{
    word-break: break-word;
}
.td-width{
    width: 200px;
}
.anchor-decoration{
    text-decoration: none;
    /* color: var(--bs-link-color); */
}
.image-holder {
    position: relative;
}

.image-holder img{
    background-color: #efefef;
}

.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
    background-color: wheat;
}

.btn-circle:hover {
  background-color: red;
}
button.delete {
    position: absolute;
    right: 0;
}
