::-webkit-scrollbar {
  display: none !important;
}

html {
  scroll-behavior: smooth;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* ----------------For Comming Sonn Pages---------------------------- */
.comming {
  background: white;
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 10%;
  border: 0 !important;
  margin-top: 150px;
  height: 50%;
  box-shadow: none !important;
}

.no_page {
  margin-top: 17%;
}

.btnHome ::hover {
  background-color: white !important;
  color: #333333 !important;
}

.card-body h1 {
  font-family: bl-bold;
  font-size: 100px;
}

.card-body h2 {
  font-family: bl-light;
}

.btn-light {
  padding: 10px;
  background-color: red;
  color: white;
  margin-top: 20px;
  border-radius: 10px;
  font-weight: bold;
}

@media (max-width: 1100px) {
  .card-body h1 {
    font-family: bl-bold;
    font-size: 50px;
  }
}

address {
  font-family: "bl-medium";
  font-size: 12px;
  color: #2c2c2c;
}

/* For togler navbar */
.navbar-light .navbar-toggler {
  color: transparent;
}

.btn.btn-danger.btnAdvertise {
  border: none;
  cursor: pointer !important;
}

.signupHomepage .eyeviewer {
  color: #333333;
  margin-top: -31px;
  position: absolute;
  right: 0;
  margin-right: 40px;
}

.invalid-feedback {
  float: left;
  margin-bottom: 20px;
}

a.gmail-open {
  background-color: white;
  width: 200px;
  color: #333333;
  padding: 12px;
  border: solid #4284f4 2px;
  border-radius: 12px;
}

.g-icon {
  margin-right: 10px;
}



@media (max-width: 1100px) {}

::-webkit-scrollbar {
  display: none !important;
}

html {
  scroll-behavior: smooth;
}

* {
  font-family: "bl-regular";
  -ms-overflow-style: none;
  scrollbar-width: none;
}

img {
  pointer-events: none;
}

.or {
  margin-top: 2%;
}

.withGoogle {
  margin-top: -2%;
  border: 1px solid #bebdbd !important;
  border-radius: 8px;
  background: #ffffff;
  color: #484848;
  padding: 12px;
}

.withGoogle:hover {
  background: #ed4d41;
  color: #ffffff;
  border: none;
}

.haveAccount {
  margin-top: 2%;
  cursor: pointer;
}

.modalBody {
  margin-top: -3%;
}

.otpNumber {
  width: 110%;
  margin-top: -10%;
  float: left;
  margin-top: 9px;
  margin-left: -2%;
}

#num-in {
  float: left;
  width: 50px;
  height: 50px;
  margin-left: 10px;
}

.pin {
  float: left;
  width: 90%;
  height: 50px;
  margin-left: 10px;
  text-align: center;
}

#mail-ga {
  margin-right: 20px;
  float: left;
  color: #666666;
}

.resend {
  color: #666666;
  margin-top: 3%;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  margin-left: 0.5%;
  cursor: pointer;
}

.congoP {
  color: #666666;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}

.googleFont {
  margin-top: -2px;
}

.onBoardingCard {
  width: 20%;
  float: left;
  height: 100%;
  background-color: #f9f9fe;
  position: fixed;
  overflow: hidden;
  font-family: bl-regular;
}

.buType {
  width: 100%;
  height: 100vh;
}

Col {
  text-decoration: none;
}

.headerLogoDashboard {
  position: absolute;
  width: 149.52px;
  height: 34px;
  left: 40px;
  top: 30px;
  cursor: pointer !important;
}

.on-boarding-sidebar {
  overflow: hidden;
}

.on-boarding-sidebar h3 {
  position: absolute;
  left: 40px;
  top: 112px;
  font-family: bl-bold;
  font-style: normal;
  font-size: 18px;
  color: #333333 !important;
  text-transform: capitalize;
}

.on-boarding-sidebar p {
  position: absolute;
  width: 80%;
  height: 60px;
  left: 40px;
  top: 183px !important;
  color: #0e0c31;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: bl-regular;
}

option.cursor.settingListItem.px-3.py-2.border-bottom :hover {
  background-color: #038d63;
}

.sideBarRow {
  border-radius: 36px;
  position: absolute;
  width: 140px;
  height: 35px;
  left: 64px;
  top: 272px;
  font-weight: 400;
  float: left;
  cursor: pointer;
}

.dashboardSideBarMenu {
  border-radius: 36px;
  position: absolute;
  width: 140px;
  height: 35px;
  left: 64px;
  font-weight: 400;
  float: left;
  cursor: pointer;
  font-family: bl-regular;
  margin-top: 40px;
  color: #999999 !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.04em;
}

.settingListItem {
  color: #7e7e7e;
  font-weight: 400;
  font-size: 15px;
  font-family: bl-regular;
}

.businessType {
  margin: left;
  margin-left: 300px;
  width: 100vw;
  margin-top: -300%;
}

.businessType1 {
  width: 95%;
  float: right;
  margin-bottom: 100px;
}

Col {
  text-decoration: none;
  border: none;
}

.cursor.settingListItem.px-3.py-2.border-bottom {
  border: none !important;
}

.second_onborading_card {
  margin-left: 20px;
}

.cardLeft:hover {
  border: 2px solid #ed4d41;
}

.card1Busi:hover {
  border: 2px solid #ed4d41;
}

.card1Busi {
  box-shadow: 0px 4px 16px rgba(73, 80, 87, 0.1);
}

.pTagRequired {
  margin-top: 2rem;
  color: #7e7e7e;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
}

.formWidth {
  width: 60%;
}

.rightArrow {
  margin-top: 100px;
}

.border1 {
  border: 2px solid red;
  width: 50%;
}

.addBalance {
  float: right;
}

.pCardText {
  font-family: bl-regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #383e41;
}

Form.Label {
  font-family: bl-regular;
  font-weight: bold;
}

.labelHead {
  font-family: bl-regular;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #383e41;
}

.upload_head {
  font-family: bl-regular;
  font-weight: 400;
}

.formLabel {
  font-family: bl-regular;
  color: #383e41;
}

option {
  color: #888888;
}

Form.Control {
  font-family: bl-regular;
}

.footer1 {
  position: absolute;
  bottom: 10px;
  width: 77%;
  margin-left: 300px;
}

.twofive {
  height: 0px;
  width: 25%;
  border-top: solid #ed4d41 4px;
  line-height: 2rem;
  margin-top: -10px;
  font-size: 0px;
}

.footer1 p {
  float: left;
  width: 80%;
}

.footer1 button {
  border: none;
  width: 200px;
  font-family: bl-bold;
  float: right;
}

.backBtn {
  margin-left: 10vw;
  padding: 3px;
}

.mainContainer {
  width: 100%;
  height: 100vh;
  font-family: bl-bold;
}

.rightContainer {
  margin-top: 5%;
  margin-left: 5%;
}

.middleConatinerDashboard {
  margin: 2%;
  margin-top: 5%;
  margin-bottom: 100px;
}

.cardFirstBilling {
  height: 2%;
}

.ft {
  bottom: 0;
  margin-bottom: 150px;
  float: left;
  position: fixed;
  height: 10%;
  width: 100%;
}

.marLeft {
  margin-left: 10%;
  position: fixed;
}

.marBusFt {
  margin-left: 32.5%;
}

div#footer1 {
  width: 100%;
  bottom: 0;
}

#button {
  background-color: #ffffff;
  margin: 1.666%;
  float: left;
}

#exit {
  margin-left: -3%;
}

.ft-left {
  background-color: #f9f9fe;
  margin-top: 87vh;
  height: 100px;
  margin-left: 30px;
  cursor: pointer;
}

.ft-left h5#button {
  margin-left: 50px;
  background-color: #f9f9fe;
  cursor: pointer;
}

.ft-right {
  float: left;
  width: 80%;
  padding: 20px;
  border-top: solid #dde2e5 2px;
  margin-top: 140px;
}

.ft-btn-one {
  width: 20%;
  padding-left: 60px;
  float: left;
}

.ft-btn-two {
  width: 60%;
  float: left;
  text-align: center;
}

.ft-btn-two p {
  margin-top: 5px;
}

.ft-btn-three {
  float: left;
  width: 20%;
  padding-right: 20px;
}

.ft-right button {
  width: 150px;
  border: none;
  background-color: #ed4d41;
  padding: 10px;
  color: white;
  border-radius: 10px;
}

.Homepage-actie {
  background-color: red;
}

.ft-btn-one button {
  background-color: transparent;
  color: #333333;
}

.ft-clr {
  border-top: solid #ed4d41 4px;
  font-size: 0px;
  float: left;
  margin-top: -23px;
  margin-left: -20px;
}

.labelHead {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: bl-regular;
}

.requiredInfo {
  color: rgb(223, 215, 5);
  border-radius: 50%;
}

placeholder {
  color: #888888;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 16px;
}

.rs {
  font-weight: 400;
  font-size: 48px;
  line-height: 48px;
  font-display: bold;
}

option.cursor.settingListItem.px-3.py-2.border-bottom {
  font-family: "bl-regular";
  font-size: 16px !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.04em;
  color: #666666;
  padding: 11px !important;
  margin-top: 10px;
  width: 100% !important;
  overflow: hidden;
}

.settingListItem:focus,
.settingListItem:active,
.settingListItem.active {
  width: 104px;
  border-radius: 36px;
  padding: 30px !important;
  color: #891e1e !important;
}

.settingListItem[aria-expanded="true"] {
  background-color: rgb(200, 12, 12) !important;
  border-color: #aed248 !important;
}

.activeList {
  background: #ed4d41 !important;
  border-radius: 36px;
  color: #ffffff !important;
  font-family: "bl-regular";
}

.activeListDashbord {
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  line-height: 130%;
  letter-spacing: 0.04em;
  background: #f0f0f0;
  border-radius: 56px;
  height: auto;
  font-family: bl-regular;
  position: absolute;
}

.dr-dashboard {
  margin-left: 0px;
  margin-top: 8px;
  padding: 10px;
  padding-left: 20px;
  width: auto;
  color: #333333;
  border-radius: 100px;
  display: flex;
  text-decoration: none;
}

.dr-dashboard.activedb {
  background: #f0f0f0;
  color: #333333;
}

.dr-dashboard span {
  padding-left: 10px;
}

.dashboard-directory {
  margin-top: 15px;
}

img.dashboard-icon {
  width: 16px;
}

p.dashboard-value-name {
  padding-left: 10px;
}

.list-group {
  width: 190px;
  margin-left: -20px;
}

button.addBalance.btn.btn-danger {
  background: #ed4d41 !important;
  border: none !important;
}

.lastPage {
  width: 100%;
  height: 60%;
  padding: 10%;
}

.bannerImg {
  width: 30%;
}

.basicListing {
  height: 32px;
  left: 199px;
  top: 231px;
  font-family: bl-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  align-items: center;
  color: #383e41;
}

.cardTextPre {
  width: 100%;
  margin-top: 10px;
  top: 279px;
  font-family: bl-regular;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  text-align: center;
}

.buttonPremum {
  width: 233px;
  height: 52px;
  left: 264px;
  top: 351px;
  background: #ed4d41;
  border-radius: 12px;
  color: white;
  font-family: bl-bold;
  border: none;
}

.maybeLater {
  top: 419px;
  font-family: bl-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #434343;
  margin-top: 5px;
}

.firstSign {
  float: right;
  color: #ed4d41;
}

.customPopupBtn {

  background: linear-gradient(0deg, #ff2a68, #ff5e3a);
  padding: 12px;

}

.rsSymbole {
  font-weight: bold;
}

.paymentMethod:hover {
  background-color: #ed4d41 !important;
  color: #f9f9fe !important;
  border: none !important;
}

.paymentMethod {
  color: #333333;
}

.uploadFile {
  color: #333333;
  width: 100px;
  border-radius: 7px;
  border: none;
}

.uploadFile:hover {
  background-color: #ed4d41 !important;
  border: none;
}

.busTypeColor {
  background-color: #ed4d41;
}

.pleaseCheckEmail {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #038d63;
  font-family: bl-regular;
}

h3 {
  font-family: bl-regular;
  font-weight: 400;
}

.forgetPass {
  cursor: pointer;
}

/* -------------------------------------Dashboard--------------------------------- */

.mainPageDashboard {
  width: 100%;
  height: 100vh;
  font-family: bl-regular;
  background-color: #f9f9f9;
}

.create-page-onboarding {
  width: 100%;
  font-family: bl-regular;
  overflow: hidden;
}

.confiramtion-page {
  width: 100%;
}

.create-confiramtion {
  width: 60%;
  margin-left: 20%;
  margin-top: 5%;
  height: 540px;
  border: none;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(73, 80, 87, 0.1);
  border-radius: 12px;
  margin-bottom: 20px;
}

.dashboardHeader {
  width: 100%;
  height: 80px;
  background-color: #ffffff;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  position: fixed;
  z-index: 99;
  margin-top: -17px;
}

.dashboardSideMenu {
  height: 100%;
  width: 20%;
  position: fixed;
  font-family: bl-regular !important;
  border-right: 1px solid #e0e0e0;
  background: #ffffff;
  box-sizing: border-box;
  position: fixed;
  margin-top: 53px;
}

.dashboardRightDiv {
  width: 80%;
  float: right;
  height: auto;
  margin-top: 50px;
  background-color: #f9f9f9;
}

.support_main_div {
  width: 80%;
  float: right;
  /* height: 100vh; */
  /* margin-top: 50px; */
  background-color: #f3ece2;
}

.support_post_right {
  width: 70%;
  float: left;
}

.support_post_right h1 {
  font-family: bl-regular;
  font-size: 104.111px;
  font-weight: 400;
}

.support_post_right p {
  font-weight: 300;
  font-size: 30px;
  font-family: bl-regular;
}

.support_post_lower {
  margin-top: 30%;
  overflow: hidden;
}

.support_post_lower p {
  font-size: 24px;
}

.support_post_left {
  width: 30%;
  float: left;
}

.support_post_submit {
  border: 1px solid black;
  height: 50px;
  padding: 10px;
  width: 55%;
  border-radius: 12px;
}

.support_post_submit button {
  align-items: center;
  padding: 2px 32px;
  width: 148px;
  height: 35px;
  background: #e94846;
  color: white;
  border-radius: 4px;
  border: none;
  float: right;
  font-family: bl-blod;
  font-size: 22px;
  margin-top: -3px;
}

.support_post_submit input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  background-color: #f3ece2;
}

.support_post_submit input .no-outline:focus {
  outline: none;
}

#hide_focus {
  outline: none;
}

.support_post_left img {
  width: 140%;
  height: 816px;
  float: left;
  margin-left: -120px !important;
  margin-top: -19%;
}

.searchIcon {
  top: 12.5%;
  bottom: 20.83%;
  font-size: 26px;
  border: 1px solid #888888;
}

.primaryButton {
  width: 200px;
  font-weight: 400;
  background: linear-gradient(0deg, #ff2a68 0%, #ff5e3a 100%);
  border-radius: 8px;
  border: none;
  font-family: bl-regular;
  padding: 12px;
  color: white;
}

.secondaryButton {
  width: 200px;
  font-weight: 400;
  background: white;
  border-radius: 8px;
  border: none;
  font-family: bl-regular;
  padding: 12px;
  color: #333;
  border: solid #333 1px;
  margin-left: 4px;
}

.filterButton {
  margin: 2px;
  padding: 4px;
  gap: 8px;
  width: 100px;
  color: #333333;
  background: #f0f0f0;
  border: 1px solid #dfdfdf;
  border-radius: 50px;
  font-family: bl-regular;
  font-weight: 400 !important;
}

.dashboardCardImage {
  width: 40px;
  height: 40px;
  float: left;
}

.cardDashboard {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border: none;
}

.cardDashboard label {
  font-weight: 400;
  font-size: 10px !important;
  line-height: 100%;
  color: #999999;
  font-family: bl-regular;
}

.cardDashboard text {
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  text-transform: capitalize;
  color: #383e41;
}

.cardDashBoardMain {
  border: none;
  background: #ffffff;
  box-shadow: 0px 2px 37px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  margin-top: 20px;
  text-align: left;
  padding: 10px;
}

.nameDasboard {
  margin-left: 65px !important;
  padding: 10px;
  width: 90%;
  margin-top: -70px;
}

.dashBoardArrow {
  color: #999999;
  font-weight: 1000 !important;
  float: right;
  font-size: 25px;
}

.arrowDiv {
  margin-left: 100px !important;
}

.myPagesDashboardArrow {
  float: right;
  margin-top: -25px !important;
  cursor: pointer;
}

.card-footer {
  background-color: white !important;
}

.cardDashboard button {
  gap: 8px;
  padding: 6px;
  width: 150px !important;
  height: auto !important;

  border-radius: 26px;
  text-transform: capitalize;
}

.active svg,
.inactive svg {
  float: left;
  margin-top: 5px;
  margin-left: 24px;
  margin-right: -20px;
}

.greenButton {
  padding: 6px;
  width: 150px !important;
  height: auto !important;
  background: #d3f4ea;
  border: 1.5px solid #038d63;
}

.slatyButton {
  padding: 6px;
  width: 150px !important;
  height: auto !important;
  border: 1.5px solid #038d63;
  background: #f0f0f0 !important;
  border: 1.5px solid #666666;
}

.inreviewButton {
  background: #fff4d7;
  border: 1.5px solid #f4b619;
}

.completedButton {
  background: #038d63;
  border: 1.5px solid #038d63;
  color: white;
  padding: 6px;
  width: 150px !important;
  height: auto !important;
}

.rejectedButton {
  background: #fad3d0;
  padding: 6px;
  width: 150px !important;
  height: auto !important;
  border: 1.5px solid #ed4d41;
}

.inreviewButton {
  border: 1.5px solid #f4b619;
  color: #1f1f1f;
  padding: 6px;
  width: 150px !important;
  height: auto !important;
}

.active {
  padding: 6px;
  width: 150px !important;
  height: auto !important;
  background: #d3f4ea;
  border: 1.5px solid #038d63;
}

.inactive {
  padding: 6px;
  width: 150px !important;
  height: auto !important;
  border: 1.5px solid #038d63;
  background: #f0f0f0 !important;
  border: 1.5px solid #666666;
}

.inreview {
  background: #fff4d7;
  border: 1.5px solid #f4b619;
}

.rejectedButton {
  background: #fad3d0;
  padding: 6px;
  width: 150px !important;
  height: auto !important;
  border: 1.5px solid #ed4d41;
}

.inreviewButton {
  border: 1.5px solid #f4b619;
  color: #1f1f1f;
  padding: 6px;
  width: 150px !important;
  height: auto !important;
}

.campaignCard {
  width: 49%;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(73, 80, 87, 0.1);
  border-radius: 12px;
  border: none;
  padding: 15px;
}

.campaignCardBilling {
  float: right;
  width: 29%;
  padding: 20px;
  margin-right: 5%;
  border: none;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(73, 80, 87, 0.1) !important;
  padding-bottom: 40px;
}

.billing {
  display: flex;
  margin-top: 10px;
  margin-left: 10px;
}

.det {
  margin-left: 70px !important;
}

.det p {
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  font-family: bl-bold;
  color: #333333;
}

.det p {
  color: #e82112 !important;
  font-family: bl-medium;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  float: right;
  margin-right: 0px !important;
  margin-top: -40px;
}

.card thead {
  background: #f0f0f0;
  border: none;
}

.billing img {
  float: left;
  margin-top: -16px;
  width: 36px;
}

#money {
  font-size: 35px;
}

.rightBiling {
  margin-left: 10px;
  margin-top: 20px;
}

.rightBiling button {
  width: 100%;
  background: #ffffff;
  border: 1px solid #333333;
  border-radius: 8px;
  margin-left: -7px;
  margin-top: 40px;
  margin-left: 0%;
  padding: 10px;
  margin-bottom: 10px;
}

.billing p {
  margin-left: 12px;
}

.rightBiling text {
  color: #e82112;
  background: #fad3d0;
  border-radius: 37px;
  width: 100%;
  padding: 10px 20px 10px 20px;
  font-family: bl-regular;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  margin-top: 20px !important;
}

.campCardHomepage {
  height: 55%;
}

/* ______________________________Campign Page_______________________ */
.classCampHeader {
  display: flex;
  justify-content: space-between;
}

.classCampHeader label {
  font-weight: 400;
  font-size: 14px;
  color: #999999;
}

.classCampHeader button {
  width: 189px;
  height: 42px;
  background: linear-gradient(0deg, #ff2a68 0%, #ff5e3a 100%);
  border-radius: 8px;
  color: #ffffff;
  border: none;
  margin-bottom: 20px;
}

.campCardUpar {
  display: flex;
}

.campCardUpar p {
  float: right;
  position: absolute;
  margin-left: 90%;
  font-weight: 400;
  font-size: 16px;
  color: #e82112;
  font-family: bl-regular;
}

.campaignCard p {
  color: #e82112;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}

.campCardUpar button {
  width: 80px;
  height: 28px;
  color: #038d63;
  border: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  background: #d3f4ea;
  border-radius: 37px;
}

.classCampHeader text {
  font-weight: 400;
  font-size: 32px;
  line-height: 100%;
  color: #333333;
}

.accordion-button:focus {
  z-index: 3;
  border-color: white !important;
  background-color: none !important;
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: white;
  box-shadow: none;
  border: none !important;
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0% !important;
}

.cardDashboard img {
  width: 60px;
  height: 60px;
}

.percentData {
  background: #d3f4ea !important;
  border-radius: 37px !important;
  width: 53px !important;
  height: 28px !important;
  color: #038d63 !important;
  font-family: bl-bold;
  text-align: center;
  padding: 4px;
  margin-left: 10px;
  margin-top: 3px !important;
  position: absolute;
}

.campCardUpar h5 {
  margin-top: -5px;
  margin-left: 13px !important;
  font-family: bl-regular;
}

.btnCampCreate {
  margin-left: 19px;
  margin-top: 17px;
}

.active12 {
  margin-top: -5px;
  margin-left: 13px;
}

.classCardDash {
  margin-top: 10px;
  float: left;
  width: 100%;
  border: none;
}

.campLevel {
  margin-top: 20px;
  color: #666666;
  font-size: 16px;
  font-weight: 400;
  padding: 0px 0px 10px 10px;
  width: 50%;
  float: left;
}

.pagination {
  width: 50%;
  margin-top: 0px;
  float: right;
  margin-top: 10px;
}

.pagination p {
  float: right;
  width: 100%;
  color: black;
  cursor: pointer;
  text-align: right;
  padding: 10px;
}

.transaction {
  float: left;
  width: 100%;
  margin-top: 20px;
  border-top: solid #dfdfdf 1px;
  height: 50px;
}

.tran-left {
  width: 57%;
  float: left;
  margin-top: 10px;
}

.tran-left p,
.tran-right p {
  color: #999999;
  font-size: 14px;
  float: right;
}

.tran-left p {
  float: left;
}

.tran-left h1 {
  font-size: 18px;
  font-family: bl-regular;
}

.tran-right {
  float: left;
  width: 30%;
  margin-top: 10px;
}

.tran-right h1 {
  font-size: 18px;
  font-family: bl-regular;
  float: right;
  color: #28cd41;
}

.campaignCard2 {
  margin-top: 40%;
}

.classCardDash th {
  color: #666666;
  font-family: bl-regular;
  border-bottom: none;
  padding: 15px;
}

.classCardDash td {
  color: #333333;
  font-size: 14px;
  padding: 15px;
  font-family: bl-regular;
}

.card {
  padding: 20px;
  box-shadow: none;
  border: none;
  box-shadow: 0px 4px 16px rgba(73, 80, 87, 0.1);
  margin-bottom: 10px;
}

.cardproductPages2 th {
  border-bottom: none;
}

.table th {
  border-bottom: none;
  padding: 15px;
}

.cardBrandFaloower td {
  padding: 15px;
}

.vackground {
  background: transparent;
  border: none;
}

.productPageDash {
  margin-left: 60%;
}

#dropdown-basic-button {
  background-color: #ff5e3a;
  border-radius: 32px;
  width: 60px;
  padding: 3px;
  border: 1px solid #ff5e3a;
  font-size: 13px;
  font-weight: 400;
  font-family: bl-regualr;
  margin-left: 8px;
  margin-top: 3px;
}

.dropDownProfile {
  position: absolute;
  top: 4.5rem;
  right: 0rem;
  width: 155px;
  padding: 4px;
  border-radius: 16px;
  background-color: white;
  margin-right: -50px;
  margin-top: -20px;
  padding-bottom: 25px;
  box-shadow: 0px 4px 16px rgba(73, 80, 87, 0.1);
}

.dropDownProfile li {
  width: 100%;
  float: left;
  margin-top: 12px;
  font-family: "bl-regular";
  text-transform: capitalize;
  color: #1f1f1f;
}

.box {
  width: 20px;
  height: 20px;
  background-color: white;
  position: absolute;
  margin-top: -13px;
  margin-left: 74px;
  transform: rotate(45deg);
  border-radius: 4px;
}

#red {
  color: red;
}

.dropDownProfile li:hover {
  cursor: pointer;
}

/*______________________My Pages_____________________________________*/
.left-image-dash {
  width: 35%;
  float: left;
}

.left-image-dash img {
  width: 50px;
  height: 50px;
}

.fallowers-card {
  float: left;
  width: 100px;
  text-align: center;
  font-family: bl-regular;
  width: 20%;
}

.posts-card {
  font-family: bl-regular;
  width: 40px;
  text-align: center;
  float: left;
  width: 10%;
}

.fifth-div {
  width: 25%;
  float: left;
  text-align: center;
}

.sixth-div {
  width: 10%;
  float: right;
}

/*_____________________Campagin Pages_______________________*/
.first-div-camp {
  width: 40%;
}

.second-div-camp {
  width: 25%;
}

.third-div-camp {
  width: 20%;
}

.fourth-div-camp {
  width: 10%;
}

.filter-btn-dash {
  background: #ffffff;
  border: 1px solid #dfdfdf;
  color: #888888;
  border-radius: 8px;
}

.filter-btn-dash:hover {
  background: #ed4d41;
  border: 1px solid #dfdfdf;
  color: #ffffff;
  border-radius: 8px;
}

.camp-img-dash {
  width: 80px;
  height: 80px;
}

.dashboardIcon {
  float: left !important;
  margin-top: 3px !important;
  margin-right: 10px !important;
  width: 15px !important;
  margin-left: 5px !important;
}

.activeListDashbord.cursor.settingListItem.px-3.py-2.border-bottom img {
  float: left;
  margin-top: 3px;
  margin-right: 10px;
  width: 15px;
  margin-left: 5px;
}

/* ------------------------Pagination------------------- */
.circle-fith {
  width: 8px;
  height: 8px;
  background: #5abb7a;
}

.filter-drop {
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(73, 80, 87, 0.1);
  border-radius: 8px;
  padding: 12px;
  font-family: bl-regular;
}

.filter-drop button {
  border: none;
  background: #ffffff;
  text-align: center;
  color: #333333;
}

.filter-drop-card {
  border: none;
  margin-top: 10px;
}

.filter-btn-dash-last {
  background: #f0f0f0;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  color: #888888;
  width: 161px;
}

#parent {
  display: flex;
}

#arrow {
  width: 200px;
  margin-left: 10px;
}

.card.col-7.cardBrandFaloower {
  border: none;
}

.cardBrandFaloower {
  width: 55%;

  float: left;
}

.cardproductPages2 {
  float: left;
  width: 40%;
  border: none;
  margin-left: 30px;
}

.cardproductPages2 th {
  padding: 15px;
}

.cardproductPages2 td {
  padding: 15px;
}

img#notification {
  margin-right: 20px;
  cursor: pointer;
}

.accordion-header {
  margin-bottom: 0;
  margin-top: 0px;
  padding-bottom: 10px;
  border-top: solid #ccc 1px;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

.search-input {
  width: 350px;
  padding: 5px;
  height: 50px;
  padding-left: 0px;
  background-color: white;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
}

.search-input #in {
  border: none;
  float: left;
  width: 86%;
  overflow: hidden;
  margin-left: 5px;
  height: auto;
}

svg#search-icon {
  width: 25px;
  height: 25px;
  margin-top: 5px;
  float: left;
  margin-left: 10px;
}

#filter-btn {
  padding: 12px;
  width: 120px;
  float: left;
}

#filter-btn1 {
  padding: 12px;
  width: 140px;
  margin-left: -10px;
}

.filterButtonUpper.collapse.show {
  margin-top: 20px;
}

.third-div-camp label {
  padding: 5px;
  text-align: center;
  margin-left: 5px;
  font-size: 12px !important;
}

.arrow-campaign {
  width: 100%;
  float: left;
  margin-top: -5px;
  margin-bottom: 5px;
}

.switch-campaign {
  width: 100%;
  float: left;
}

.switch-campaign label {
  margin-top: 15px;
  float: right;
}

.pagination-result {
  width: 90%;
  float: left;
  margin-top: 30px;
}

.pagination-result p {
  float: right;
}

#prev,
#next {
  cursor: pointer;
  margin-left: 20px;
}

.pagination svg {
  margin-left: 15px;
  width: 30px;
  height: 30px;
}

.modal-content {
  border: none;
  padding: 20px;
  margin-top: 25%;
}

/* .modal-content button {
  height: 50px;
} */

.modal-backdrop.show {
  opacity: 0.8;
}

p#username {
  float: left;
  margin-top: 15px;
  margin-right: 20px;
  text-transform: capitalize;
  font-family: "bl-medium";
  color: #1f1f1f;
}

.span-username {
  float: left;
  margin-top: 10px;
  margin-right: 20px;
  text-transform: capitalize;
  font-family: "bl-medium";
  color: #1f1f1f;
}

p#username::before {
  content: "Hi, ";
  font-family: "bl-regular";
}

.navbar-nav {
  margin-top: -10px;
}

.NoPageSupport{
  width: 90%;
  max-height: 100vh;
  text-align: center;
  margin-top: 70px;
  justify-content: center;
  align-items: center;
}

.NoPageSupport p{
  font-size: 40px;
  font-family: "bl-regular";
  color: #333333;
  text-align: center;
  width: 100%;
  opacity: 0.6;
  float: left;
  margin-bottom: 100px;
}

.NoPage {
  width: 90%;
  max-height: 100vh;
  text-align: center;
  margin-top: 70px;
  margin-bottom: 300px;
  justify-content: center;
  align-items: center;
}

.NoPage p {
  font-size: 40px;
  font-family: "bl-regular";
  color: #333333;
  text-align: center;
  width: 100%;
  opacity: 0.6;
  float: left;
  margin-bottom: 100px;
}

.NoPage button {
  background-color: #ed4d41;
  border: none;
  color: white;
  padding: 12px;
  border-radius: 8px;
  margin-top: 30px;
  width: 200px;
}

img#nopage {
  float: left;
  width: 400px;
  margin-left: 29%;
  margin-top: 0px;
  margin-bottom: 10px;
  opacity: 0.8;
}

.dateRangePicker {
  z-index: 999 !important;
  width: 200px;
}

.day-filter {
  width: 250px;
}

div[aria-expanded="false"] .firstSign {
  display: none;
  color: green !important;
}

.card-body {
  cursor: pointer;
}

div[aria-expanded="true"] .card-body {
  border: solid #ed4d41 1px;
  border-radius: 6px;
}

.card-body:hover {
  border: none !important;
}

.ant-picker-dropdown.css-dev-only-do-not-override-1i536d8.ant-picker-dropdown-range.ant-picker-dropdown-placement-bottomLeft {
  z-index: 99999;
  margin-left: 350px;
  position: fixed;
}

.progress-bar {
  border: 1px solid #ed4d41;
  height: 0px;
  border-radius: 20px;
  z-index: 999;
}

.background-progessbar {
  background-color: #d4d4d4;
  height: 3px;
  width: 110%;
  bottom: 73px;
  position: absolute;
  font-family: "bl-regular";
  margin-left: -10%;
}

.background-progessbar-on {
  background-color: #d4d4d4;
  height: 3px;
  width: 100%;
  bottom: 68px;
  font-family: "bl-regular";
  position: fixed;
  z-index: 999;
}

.create-page {
  width: 70%;
  position: absolute;
  left: 15%;
  top: 10%;
  bottom: 5%;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(73, 80, 87, 0.1);
  border-radius: 12px;
  border: none;
  font-family: "bl-regular";
  margin-top: 20px;
  overflow: hidden;
}

.btn-nxt-bck {
  position: absolute;
  bottom: 0px;
  width: 99%;
  float: left;
  padding: 10px;
  border-radius: 10px;
  background: white;
  margin-left: 0.5%;
  font-family: "bl-regular";
  height: 73px;
}

.btn-nxt-bck button#bck {
  float: left;
  background: none;
  border: none;
  font-size: 18px;
  font-family: "bl-medium";
  margin-left: 50px;
}

.btn-nxt-bck button#nxt {
  float: right;
  width: 160px;
  padding: 6px;
  border: none;
  background: #ed4d41;
  border-radius: 8px;
  font-size: 18px;
  color: white;
  margin-right: 50px;
  font-family: "bl-regular";
}

.btn-nxt-bck-on-boarding {
  position: absolute;
  bottom: 4px;
  width: 80%;
  float: left;
  padding: 10px;
  border-radius: 10px;
  background: white;
  margin-left: 0.5%;
  font-family: "bl-regular";
  margin-bottom: 0px;
  position: fixed;
  overflow: hidden;
}

.btn-nxt-bck-on-abroad {
  position: absolute;
  bottom: 4px;
  width: 85%;
  float: left;
  border-radius: 10px;
  background: white;
  font-family: "bl-regular";
  margin-bottom: 0px;
}

.btn-nxt-bck-on-abroad button#nxt {
  float: right;
  width: 160px;
  padding: 6px;
  border: none;
  background: #ed4d41;
  border-radius: 8px;
  font-size: 18px;
  color: white;
  margin-right: 100px;
  font-family: "bl-regular";
}

.btn-nxt-bck-on-boarding button#bck {
  float: left;
  background: none;
  border: none;
  font-size: 18px;
  font-family: "bl-medium";
  margin-left: 0px;
}

.btn-nxt-bck-on-boarding button#nxt {
  float: right;
  width: 160px;
  padding: 6px;
  border: none;
  background: #ed4d41;
  border-radius: 8px;
  font-size: 18px;
  color: white;
  margin-right: 100px;
  font-family: "bl-regular";
}

#cntr {
  float: left;
  text-align: center;
  margin-left: 33%;
  margin-top: 5px;
}

.create-page-div {
  padding: 40px;
}

.create-page-div h1 {
  font-family: "bl-regular" !important;
  margin-top: 30px;
  color: #1f1f1f;
}

.create-page-section {
  width: 100%;
  font-family: "bl-regualr";
  overflow: scroll;
  height: 600px;
  margin-top: -20px;
  padding: 40px;
}

.cp-section-div1 {
  float: left;
  width: 60%;
  font-family: "bl-regular";
  padding: 40px;
  height: 100%;
  padding-top: 0px;
  overflow: scroll;
  padding-bottom: 140px;
}

.cp-section-div2 {
  font-family: "bl-regular";
  float: left;
  width: 40%;
  padding: 20px;
  padding-top: 0px;
  height: 100%;
  overflow: scroll;
  height: auto;
  pointer-events: none;
  padding-bottom: 140px;
}

.cp-section-input {
  width: 300px;
  border: 1px solid #999999;
  border-radius: 8px;
  font-family: "bl-regular";
}

.drag-drop {
  padding: 34px;
  padding: 34px 100px;
  gap: 10px;
  width: 100%;
  height: 150px;
  border: 1.5px dashed #999999;
  border-radius: 12px;
  cursor: pointer;
  font-family: "bl-regular";
}

.darg-icon {
  margin-left: 37%;
  font-size: 10px;
}

.drag-browser {
  color: #ed4d41;
  font-family: "bl-regular";
  font-weight: 600;
}

.banner-section p {
  font-size: 14px;
  margin-top: 20px;
}

.drag-logo {
  box-sizing: border-box;
  cursor: pointer;
  font-family: "bl-regular";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 96px;
  height: 96px;
  left: 236px;
  background: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  z-index: 9999999;
  margin-top: -96px;
  text-align: center;
}

.FileUpload {
  margin-top: 22px;
  margin-left: -37px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}

.drag-logo img {
  text-align: center;
  margin-top: 0%;
  z-index: -99;
}


.file_upload {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 96px;
  height: 96px;
  cursor: pointer;
  background-color: #038d63;
}

.file_upload_d {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 96px;
  height: 96px;
  cursor: pointer;
  margin-top: -96px;
}

.add-logo {
  background-color: transparent;
  font-size: 12px;
  font-weight: 400;
  border: none;
  font-family: "bl-bold";
  margin-top: 13px;
}

.text-muted {
  font-family: "bl-regular";
}

.mar {
  margin-top: 20px;
}

.mar:first-child {
  margin-top: 0px;
}

.cp-right-icon {
  display: flex;
  justify-items: flex-start;
  width: 100%;
}

.cp-right-icon-home {
  float: right;
  margin-left: 90%;
  font-family: "bl-regular";
}

.cp-card {
  background: #ffffff;
  border-radius: 12px;
  height: auto;
  margin-top: 10px;
  border: solid aliceblue 1px;
  font-family: "bl-regular";
}

.right-header-img {
  width: 100%;
  margin-top: 10px;
  border-radius: 0px;
}

.right-headre-overlap {
  width: 86px;
  height: 86px;
  border: 2px solid #ffffff;
  box-shadow: 0px 4px 16px rgba(47, 47, 47, 0.1);
  border-radius: 20px;
  font-family: "bl-regular";
}

.right-header-over {
  width: 86px;
  height: 86px;
  border-radius: 10px;
  overflow: hidden;
  margin-top: -20px;
  font-family: "bl-regular";
}

.pan_preview_image {
  width: 120px;
  height: 120px;
  border-radius: 10px;
  overflow: hidden;
  font-family: "bl-regular";
  margin-top: 20px;
}

.right-header-publish {
  width: 86px;
  height: 86px;
  border-radius: 10px;
  overflow: hidden;
  font-family: "bl-regular";
}

.cp-follower {
  display: flex;
  text-align: center;
  margin-left: 27%;
  margin-top: -10px;
}

.cp-follower text {
  font-family: "bl-bold";
  color: #1f1f1f;
}

.cp-follower-right {
  margin-left: 10px;
}

.headre-title {
  font-weight: 500;
  font-size: 16px;
  font-family: "bl-bold";
  font-weight: 500;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.04em;
  margin-top: -2px;
}

.cp-text {
  color: #666666;
  font-family: "bl-regular";
  font-size: 12px;
  font-weight: 500;
}

.follow-button {
  width: 100%;
  background: #ed4d41;
  border-radius: 8px;
  color: #ffffff;
  height: 44px;
  border: none;
  gap: 10px;
  font-weight: 1000;
  font-family: "bl-regular";
  margin-top: 20px;
  font-family: "bl-regular";
  font-size: 17px;
}

.nav-cp {
  display: flex;
}

.nav-cp span {
  margin: 10px;
  font-family: "bl-regular";
  font-size: 14px;
  color: #999999;
  letter-spacing: 0.04em;
  font-weight: 400;
  font-size: 14px;
  margin-top: -7px;
  margin-bottom: -10px;
  font-family: "bl-regular";
}

.nav-cp label {
  margin: 10px;
  font-family: "bl-regular";
  font-size: 14px;
  margin-bottom: -10px;
  width: 150px !important;
  height: 38px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  background: #f0f0f0;
  border: 1px solid #dfdfdf;
  border-radius: 50px;
  padding: 8px 12px;
  font-family: "bl-regular";
}

.third-page {
  display: flex;
}

.third-page text {
  margin: 10px;
  width: 70px;
  padding: 14px;
  height: 30px;
  font-family: "Geomanist";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: black;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(73, 80, 87, 0.1);
  border-radius: 22px;
  font-family: "bl-regular";
}

.fourth-page-product {
  display: flex;
}

.fourth-page-product text {
  margin: 5px;
  width: auto;
  padding: 20px;
  border: solid #ed4d41 0.5pt;
  height: 30px;
  font-family: "Geomanist";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #ed4d41;
  background: #ffffff;
  border-radius: 22px;
  font-family: "bl-regular";
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.fourth-page-product text:hover {
  background-color: #ed4d41;
  color: white;
  box-shadow: 0px 4px 16px #ed4d41;
}

.add-section-btn {
  width: 100%;
  color: #888888;
  background: #f0f0f0;
  border: 1px solid #999999;
  border-radius: 8px;
  align-items: center;
  padding: 19px 148px;
  font-family: "bl-regular";
  margin-bottom: 90px !important;
}

.add-product-btn {
  width: 100%;
  color: #888888;
  background: #f0f0f0;
  border: 1px solid #999999;
  border-radius: 8px;
  align-items: center;
  padding: 19px 148px;
  font-family: "bl-regular";
  margin-top: 20px;
}

.publish-now-card {
  box-sizing: border-box;
  position: absolute;
  width: 80%;
  margin-left: 5%;
  height: 286px;
  box-shadow: none;
  margin-bottom: 60px;
  margin-bottom: 40px;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 12px;
}

.card-div-logo {
  width: 100%;
  float: left;
}

.card-div-logo-right {
  margin-top: 20px;
  margin-top: 7px;
  width: 30%;
  float: left;
}

.small-logo {
  width: 70%;
  float: left;
}

.small-logo text {
  color: #383e41;
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  margin-top: 30px;
  font-family: "bl-regular";
}

.card-div-logo-right button {
  align-items: center;
  width: 80px;
  height: 38px;
  margin: 10px;
  background: #ffffff;
  border: 1.5px solid #333333;
  border-radius: 8px;
  float: right;
}

.card-right-button {
  margin-top: 40px;
}

.card-right-button-inner {
  float: right;
  margin-right: 12px;
}

.card-right-button-inner button {
  width: 126px;
  height: 38px;
  border: none;
  color: #ffffff;
  background: #ed4d41;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-family: "bl-regular";
}

.publish-now-card2 {
  position: absolute;
  float: right;
}

.create-page-confirmation {
  width: 100%;
  font-family: "bl-regualr";
  height: auto;
  overflow: hidden;
}

.cp-third-page-head label {
  font-family: "bl-bold";
}

.cp-third-page-head p {
  font-family: "bl-regular";
  text-align: left;
  font-size: 14px;
  line-height: 130%;
  width: 100%;
  letter-spacing: 0.04em;
  color: #666666;
}

.see-all {
  float: right;
  font-family: "bl-bold";
  color: #ed4d41;
}

.cp-fourth-product {
  font-family: "bl-bold";
  color: #666666;
}

.inner-image-cp {
  width: 130px;
  height: 130px;
  left: 12.5px;
  top: 0px;
  background: rgba(255, 185, 185, 0.25);
  border-radius: 126px;
}

.cp-inner-logo-card1 {
  margin-top: 20px;
  width: 100%;
  margin-top: 10px;
  text-align: center;
  overflow: hidden;
  text-align: center;
}

cp-inner-logo-card1 img {
  width: 100%;
  box-shadow: 0px 5px 31px rgba(49, 49, 49, 0.1);
}

.cp-inner-logo-card2 {
  margin-top: 20px;
  width: 50%;
  margin-top: 10px;
  margin-left: 40px;
  text-align: center;
}

.cp-inner-logo-text {
  text-align: center;
}

.cp-inner-logo-text text {
  font-family: "bl-bold";
  font-weight: 500;
  font-size: 13px;
  line-height: 110%;
  margin-top: 12px !important;
  text-align: center;
  margin-top: 13px;
}

.cp-inner-logo-text p {
  font-family: "bl-regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #717171;
  margin-top: 10px;
  text-align: center;
}

.know-more {
  width: 50%;
  color: #333333;
  background: #ffffff;
  border-radius: 11px;
  padding: 4px;
  font-family: "bl-regular";
  border-radius: 11px;
  border: 1px solid #333333 !important;
}

.horizental-add {
  display: flex;
  width: 100%;
}

#nav-profile {
  cursor: pointer;
  font-size: 35px;
  margin-top: 10px;
  color: #ed4d41;
}

img#profile-pic {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  margin-top: 8px;
  margin-left: -10px;
}

button#btn-flow {
  border: none;
  background-color: transparent;
  opacity: 1;
  width: 40px;
  height: 40px;
  border-radius: 100px;
}

.inner-soon {
  width: 100%;
  text-align: center;
  font-size: 22px;
  margin-top: 50px;
}

.form-control:focus {
  box-shadow: none;
}

img.connect-with-us {
  width: 100%;
}

.rg-form {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control {
  height: 50px;
  padding: 0.375rem 0.75rem;
}

.rg-form {
  height: 50px;
}

.on-boarding-b {
  width: 100%;
  display: flex;
}

.on-boarding-b-left {
  width: 20%;
  height: 100vh;
}

.on-boarding-b-right {
  width: 80%;
  float: left;
  right: 0;
}

.right-width-inner {
  width: 758px;
  height: 704px;
  background: #e9392c;
  border-radius: 23px;
}

.right-width-inner img {
  padding: 30px;
  border-radius: 23px;
}

.brand-logo-form {
  width: 100%;
  height: 100px;
  border: none;
  border-radius: 16px;
}

.solution-third-section {
  width: 100%;
}

.left-width-third-section {
  width: 60%;
  float: left;
  margin-top: 30px;
}

.left-width-third-section img {
  width: 60%;
  height: 30%;
}

.right-width-third-section {
  width: 40%;
  float: left;
  margin-top: 5%;
}

.right-width-third-section h1 {
  font-family: "bl-bold";
}

.inner-mess {
  color: #ed4d41 !important;
}

.sponsored-display {
  margin-top: 20px;
}

.sponsored-display-card {
  background: #ffffff;
  box-shadow: 0px 5px 31px rgba(49, 49, 49, 0.1);
  border-radius: 36px;
  border: none;
  padding: 40px;
}

.sponsored-messaging {
  margin-top: 20px;
}

.sponsored-display-card {
  background: #ffffff;
  box-shadow: 0px 5px 31px rgba(49, 49, 49, 0.1);
  border-radius: 36px;
  border: none;
  width: 523px;
  padding: 40px;
}

.sponsored-display-card1 {
  background: #ffffff;
  box-shadow: 0px 5px 31px rgba(49, 49, 49, 0.1);
  border-radius: 36px;
  border: none;
  padding: 40px;
  width: 523px;
  margin-top: 10px;
}

.sponsored-messaging-card {
  background: #ffffff;
  box-shadow: 0px 5px 31px rgba(49, 49, 49, 0.1);
  border-radius: 36px;
  border: none;
  padding: 40px;
  z-index: -10;
}

.sponsored-display-card label,
.sponsored-display-card1 label {
  font-family: "bl-bold";
  font-size: 22px;
}

.sponsored-messaging-card label,
.sponsored-messaging-card1 label {
  font-family: "bl-bold";
  font-size: 22px;
}

.sponsored-messaging-card1 {
  background: #ffffff;
  box-shadow: 0px 5px 31px rgba(49, 49, 49, 0.1);
  border-radius: 36px;
  border: none;
  padding: 40px;
  margin-top: 10px;
}

.sponsored-display {
  width: 100%;
  margin-top: 100px;
}

.left-sponsored-display {
  width: 60%;
  float: left;
}

.right-sponsored-display {
  width: 40%;
  float: left;
}

.right-sponsored-display img {
  width: 100%;
  height: 600px;
}

.solution-fifth-section {
  width: 100%;
}

.solution-inner-main-div {
  width: 100%;
  padding: 50px;
  background: #ffffff;
  box-shadow: 0px 5px 31px rgba(49, 49, 49, 0.1);
  border-radius: 36px;
  height: 446px;
  overflow: hidden;
}

.solution-inner-main-div h1 {
  font-family: "bl-bold";
}

.solution-inner-main-div p {
  font-family: "bl-regular";
  font-size: 30px;
  margin-top: 20px;
}

.left-solution-fifth-section {
  width: 60%;
  float: left;
}

.right-solution-fifth-section {
  width: 40%;
  float: left;
}

.sol-fif-card {
  margin-top: 70px;
}

.right-solution-fifth-section img {
  margin-bottom: -25px !important;
  overflow: hidden;
  width: 75%;
  float: right;
  margin-right: 20px;
  margin-top: 10px;
}

.solution-six-section {
  width: 100%;
}

.inner-main-six-div {
  width: 100%;
  text-align: center;
  margin-top: 140px;
}

.simple-step-outer {
  display: flex;
  margin-left: -20px;
}

.solution-right-image {
  float: right;
  overflow: hidden;
  padding: 0px;
}

.solution-right-image img {
  width: 100%;
  float: left;
  margin-left: 45px;
}

.simple-step {
  color: #333333;
  font-family: bl-regular;
  border-radius: 33px;
  box-shadow: 0px 5px 31px rgba(49, 49, 49, 0.1);
}

.introducing-card {
  width: 100%;
  margin-left: 0px;
  justify-content: space-evenly;
  margin-bottom: 100px;
}

.simple-step img {
  width: 60px;
  height: 60px;
  text-align: center;
  margin-top: 10%;
}

.simple-step p {
  font-family: bl-medium;
  font-size: 15px;
  width: 120px;
  text-align: left;
  margin-left: 20px;
}

.inner-simple-step {
  margin: 10px;
}

.inner-simple-step img {
  margin-left: 30px;
}

.solution-section-eight {
  width: 100%;
}

.solution-section-inner-main-div {
  background: linear-gradient(91.44deg,
      #f04336 0%,
      rgba(240, 77, 65, 0.92) 86.41%);
  border-radius: 24px;
  width: 100%;
  float: left;
  height: 370px;
  margin-bottom: 50px;
  margin-top: 40px;
}

.left-section-solution-eight-div {
  float: left;
  width: 60%;
  padding: 40px;
}

.left-section-solution-eight-div p {
  color: #ffffff;
  font-size: 48px;
  width: 600px;
  font-family: "bl-regular";
}

.left-section-solution-eight-div button {
  background: #ffffff;
  box-shadow: 0px 1px 34px rgba(136, 136, 136, 0.1);
  border-radius: 12px;
  border: none;
  width: 208px;
  height: 65px;
  font-family: "bl-regular";
  color: #ed4d41;
}

.right-section-solution-eight-div {
  width: 40%;
  float: left;
}

.right-section-solution-eight-div img {
  width: 100%;
  height: 370px;
  overflow: hidden;
}

/* Utilities Page */
.utilities-main-container {
  width: 100%;
  background: #f8fcff;
  float: left;
}

.utilities-first-section {
  width: 100%;
}

.utilities-first-section-left {
  width: 60%;
  float: left;
  margin-top: 90px;
  overflow: hidden;
}

.utilities-first-section-left p {
  font-size: 24px;
  color: #000000;
  width: 396px;
  font-family: "bl-regular";
}

.utilities-first-section-left h1 {
  font-family: bl-bold;
}

.utilities-second-section {
  text-align: center;
}

.utilities-first-section-right {
  width: 40%;
  float: left;
}

.utilities-first-section-right img {
  width: 100%;
}

#utilities-inner-btpwebsite {
  width: 183px;
  height: 46px;
  background: #ffffff;
  font-family: bl-medium;
  border: 2px solid #333333;
  box-shadow: 0px 1px 34px rgba(136, 136, 136, 0.1);
  border-radius: 12px;
}

.utilities-second-inner-section {
  padding: 10px;
  text-align: center;
  width: 100%;
  margin-left: 0px;
}

.utilities-card {
  border: none;
  background: #ffffff;
  width: 90%;
  height: auto;
  box-shadow: 0px 5px 31px rgba(49, 49, 49, 0.1);
  border-radius: 38px;
  margin-top: 30px;
  margin-left: 20px;
}

.utilities-card h1 {
  font-weight: 500;
  font-size: 20px !important;
  line-height: 130%;
  font-family: bl-bold;
}

.utilities-reach {
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #666666;
  font-family: "bl-regular";
}

.utilities-reach-spons {
  font-weight: 500;
  font-size: 58px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.02em;
  font-family: "bl-medium";
  color: #333333;
  margin-top: 110px;
}

.utilities-fourth-section {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 170px;
}

.utilities-fourth-main-section {
  width: 100%;
}

.utilities-fourth-section-left {
  width: 40%;
  float: left;
  margin-top: 70px;
}

.utilities-fourth-section-left img {
  width: 90%;
}

.utilities-fourth-section-right {
  width: 60%;
  float: left;
  margin-top: 70px;
}

.utilities-fourth-section-right-inner-div {
  width: 669px;
  height: 210px;
  background: #ffffff;
  box-shadow: 0px 5px 31px rgba(49, 49, 49, 0.1);
  border-radius: 36px;
  padding: 40px;
}

.utilities-fourth-section-right-inner-div h3 {
  font-size: 32px;
  color: #333333;
  font-family: "bl-regular";
  font-weight: 500;
}

.utilities-fourth-section-right-inner-div p {
  width: 463px;
  color: #333333;
  font-family: "bl-regular";
  margin-top: 15px;
  font-size: 16px;
}

.utilities-fourth-main-section h1 {
  text-align: center;
}

.utilities-card img {
  padding: 20px;
}

svg.right {
  position: absolute;
  right: 0;
  margin-top: -30px;
  margin-right: 40px;
}

svg.close-right {
  position: absolute;
  right: 0;
  margin-right: 40px;
  margin-top: -30px;
}

#gap {
  margin-top: 30px;
  float: left;
  width: 100%;
}

#settings {
  margin-right: 10px;
  font-size: 20px;
  float: left;
  margin-top: 3px;
}

#logout {
  margin-left: -4px;
  font-size: 25px;
  margin-right: 10px;
  float: left;
  margin-top: 3px;
}

.disable {
  pointer-events: none;
}

.disable input {
  background-color: #fff4d7;
}

img#defu {
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

div#nameDasboard {
  width: 200px !important;
  overflow: hidden;
}

.section-btn-add {
  color: #038d63;
}

.section-btn-add label {
  color: #038d63;
  font-size: larger;
  font-family: bl-bold;
}

#date-time {
  font-weight: 400;
  font-size: 12px !important;
  line-height: 100%;
  color: #999999;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 135px;
  font-family: bl-regular;
}

img.right-header-over {
  box-shadow: 0px 3px 12px rgba(47, 47, 47, 0.1);
}

p.headre-title.card-text {
  margin-top: 10px;
}

.lp {
  margin-top: -6px;
  width: 100px;
}

.dropdown-menu.show {
  box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);
  width: 100px;
  text-decoration: none;
  font-weight: 300;
  font-size: 18px;
  line-height: 46px;
  color: #666666;
  border: none;
  font-family: bl-regular;
}

#utilities-inner-btpwebsite:focus,
#utilities-inner-btpwebsite:active,
#utilities-inner-btpwebsite.active {
  width: 183px;
  background: #ed4d41;
  box-shadow: 0px 1px 34px rgba(136, 136, 136, 0.1);
  border-radius: 12px;
  height: 46px;
  border: none;
  color: #ffffff;
  font-family: bl-medium;
}

#utilities-inner-app {
  width: 104px;
  height: 46px;
  background: #ffffff;
  font-family: bl-medium;
  border: 2px solid #333333;
  box-shadow: 0px 1px 34px rgba(136, 136, 136, 0.1);
  border-radius: 12px;
  color: black;
}

#utilities-inner-app:focus,
#utilities-inner-app:active,
#utilities-inner-app.active {
  width: 104px;
  background: #ed4d41;
  box-shadow: 0px 1px 34px rgba(136, 136, 136, 0.1);
  border-radius: 12px;
  height: 46px;
  border: none;
  color: #ffffff;
  font-family: bl-medium;
}

.right-side-inner-div img {
  width: 100%;
  height: 708px;
}

.right-side-inner-div {
  width: 758px;
  left: 0px;
  background: linear-gradient(180deg, #e9392c 0%, rgba(238, 86, 74, 0) 33.09%);
}

.right-width-inner {
  width: 758px;
  height: 550px;
  left: 0px;
  background: #e9392c;
}

.right-width-inner img {
  margin-top: -100px;
}

#highlights-your {
  color: #999999;
}

.pointer-none {
  width: 190px;
  margin-left: -20px;
  pointer-events: none;
}

.publish_img {
  width: 100px;
  height: 100px;
  border-radius: 23px;
}

.multi_selction_bootm {
  margin-bottom: 100px;
}

.preview-image {
  padding: 10px 10px 10px 10px;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.image-text {
  text-align: center;
}

.attachment {
  height: 70px;
  display: block;
}

img.attachment:before {
  content: " ";
  display: block;
  position: absolute;
  height: 70px;
  width: 70px;
  background-color: #eeeeee;
}

.swal2-confirm.swal-button {
  margin-right: 4px;
}

.validation-error {
  color: red;
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.toast:not(.showing):not(.show) {
  opacity: 0;
}

.toast.hide {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container> :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.verify_if_not_verified {
  width: 100%;
  font-size: 14px;
  margin-top: 50px;

  margin-bottom: 20px;
  margin-left: 25px;
  float: left;
}

.verify_if_not_verified h1 {
  box-shadow: 0px 4px 16px rgb(73 80 87 / 10%);
  background: white;
  width: 96%;
  padding: 30px;
}

.abroad {
  width: 100%;
  float: right;
  height: 90vh;
}

.hr_abroad {
  border: 1px solid #ed4d41;
  height: 0px;
  border-radius: 20px;
  z-index: 999;
}

.spinner {
  height: 65px;
  width: 65px;
  position: relative;
  margin: 20% auto;
  display: flex;
}

img.spinner-image {
  height: 65px;
}

.verify_user {
  width: 50%;
  margin-left: 25%;
  margin-top: 5%;
  box-shadow: 0px 4px 16px rgba(73, 80, 87, 0.2);
  padding: 100px;
  border-radius: 12px;
  overflow: hidden;
  padding-top: 70px;
  margin-bottom: 20px;
}

textarea#section_description {
  margin-bottom: 20px;
}

.sponsored {
  margin-top: 20px;
}

.sponsored tr {
  margin-top: 10px;
}

.sponsored tr {
  margin-top: 10px;
  float: left;
  background: #ffffff;
  box-shadow: 0px 2px 37px rgba(0, 0, 0, 0.05);
  padding: 20px;
  border-radius: 12px;
  width: 100%;
  padding-bottom: 0px;
}

.sponsored td {
  border: none;
}

#date {
  width: 100%;
  float: left;
  text-align: center;
  color: #999999;
  font-size: 12px;
  margin-top: 10px;
  font-family: "bl-regular";
}

#edit {
  border: none;
  float: right;
  position: absolute;
  right: 0;
  margin-top: -15px;
  z-index: 9;
  margin-right: 60px;
  padding: 0;
  color: #ed4d41;
}

#edit:hover {
  background: none;
  color: black;
}

#delete {
  border: none;
  float: right;
  position: absolute;
  right: 0;
  z-index: 9;
  margin-right: 50px;
  padding: 0;
  color: #ed4d41;
  margin-top: 60px;
}

#delete:hover {
  background: none;
  color: black;
}

#pay {
  background: #ffffff;
  border: 1px solid #ed4d41;
  border-radius: 8px;
  width: 100px;
  padding: 8px;
  font-size: 12px;
  margin-left: 10px;
  margin-top: 10px;
}

#paid {
  background: #ffffff;
  border: 1px solid #ed4d41;
  border-radius: 8px;
  width: 100px;
  padding: 8px;
  font-size: 12px;
  margin-left: 10px;
  margin-top: 10px;
}

.anchor {
  text-decoration: none;
}

.anchor:hover {
  color: black;
}

#sponsored-heading {
  color: #383e41;
  font-size: 32px;
  font-family: "bl-regular";
}

#sponsored-heading-author {
  width: 100%;
  margin-top: 20px;
  color: #383e41;
  font-size: 32px;
  font-family: "bl-regular";
  float: left;
  margin-bottom: 20px;
}

p#exit {
  font-size: 16px;
  color: #383e41;
  border: none;
  background: none !important;
  font-family: "bl-regular";
  cursor: pointer;
}

img#exit-btn {
  transform: rotate(180deg);
  width: 8px;
  margin-right: 10px;
  margin-top: -2px;
}

#right-heading {
  float: right;
  color: #999999;
}

.editor {
  width: 60%;
  margin-top: 32px;
}

#title-input {
  height: 52px;
  font-size: 14px;
  border: 1px solid #999999;
  margin-bottom: 20px;
}

#description {
  height: 181px;
  font-size: 14px;
  margin-bottom: 20px;
  padding: 14px;
  border: 1px solid #999999;
}

#add-cover {
  width: 100%;
  height: 142px;
  border: 1.5px dashed #999999;
  border-radius: 12px;
  margin-bottom: 20px;
}

#add-cover:hover {
  background: none;
  color: #000000;
}

.top {
  margin-top: 20px;
}

button.secondaryButton.btn.btn-primary:hover {
  background: transparent;
  color: black;
  border: solid black 1px;
}

#author-description {
  height: 100px;
  padding: 20px;
  border: 1px solid #999999;
}

#featured-image {
  width: 10%;
  float: left;
}

#post-title {
  width: 40%;
  float: left;
  overflow: hidden;
}

#post-author {
  width: 20%;
  float: left;
  overflow: hidden;
}

#post-status {
  width: 20%;
  float: left;
}

#post-pay-btn {
  width: 10%;
  float: left;
}

#post-next {
  width: 10%;
  position: absolute;
  right: 0;
  margin-left: 0px;
}

#post-next img {
  margin-left: 70px;
  margin-top: 30px;
}

#sp-banner {
  width: 102%;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
}

.btn_upload_onboarding {
  width: 120px;
  height: 40px;
  color: #434343;
  font-family: bl-regular;
  font-weight: 500;
  border: 1px solid #434343;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 4px 16px rgba(73, 80, 87, 0.1);
}

#delete {
  width: 18px;
  height: 18px;
  margin-left: 15px;
  margin-top: -20px;
  cursor: pointer;
}

#edit_btn {
  width: 50px !important;
  height: 40px;
  margin-top: -40%;
  margin-left: 40%;
  color: green;
  background-color: white;
  border: none;
  font-family: bl-regular;
}

.product_card.card {
  box-shadow: none;
  border-radius: 14px;
  border: solid black 0.1pt;
}

.product_tog {
  margin-left: -60px;
}

button.add-new.btn.btn-primary {
  margin-left: 82% !important;
  position: absolute;
  float: right;
  background: none;
  color: black;
  border: none;
  margin-top: 0px;
}

.cp-card.preview-page.card {
  border: solid grey 0.5pt;
}

.border-0.cp-inner-logo-card1.inner-preview-card.card {
  box-shadow: none;
}

#create-1,
#create-2,
#create-3,
#create-4 {
  margin-top: -180px;
}

#create_sec-1,
#create_sec-2 #create_sec-3 #create_sec-4 #create_sec-5 #create_sec-6 #create_sec-6 {
  margin-top: -100px;
}

.preview-page.dynamic-card.cp-card.card {
  margin-top: 160px;
  max-height: 580px;
  overflow: scroll;
  box-shadow: none;
}

.preview-page.dynamic-card_sec.cp-card.card {
  margin-top: 375px;
  max-height: 580px;
  overflow: scroll;
}

svg.add-icon {
  float: left;
  margin-top: 4px;
  margin-left: -10px;
  margin-right: 7px;
}

button.add-new.section.btn.btn-primary {
  margin-left: 69% !important;
  width: 112px;
  text-align: left;
  border-right: solid 0.5pt grey;
  border-radius: 0px;
}

.me-auto.customer.navbar-nav {
  margin-right: 18px !important;
  float: right;
  background-color: #f1f1f1;
  padding: 10px;
  padding-left: 20px;
  font-family: 'bl-regular';
  padding-right: 20px;
  cursor: pointer;
}

#customerIcon {
  float: right;
  margin-top: 5px;
}

.messagePopup {
  position: fixed;
  background-color: white;
  width: 400px;
  right: 0;
  padding: 0px;
  bottom: 0;
  border-radius: 16px;
  padding-bottom: 20px;
  margin-bottom: -10px;
  padding-top: 15px;
  box-shadow: rgba(101, 119, 134, 0.2) 0px 0px 15px, rgba(101, 119, 134, 0.15) 0px 0px 3px 1px;
  margin-right: 10px;
  cursor: pointer;
  z-index: 99999;
}

.messagePopup_side {
  position: fixed;
  width: 400px;
  right: 0;
  padding: 0px;
  bottom: 0;
  border-radius: 16px;
  padding-bottom: 20px;
  margin-bottom: -10px;
  padding-top: 15px;
  margin-right: 10px;
  cursor: pointer;
  z-index: 99999;
  height: 500px;
  margin-right: 450px;
}

.messageInput {
  box-shadow: rgba(101, 119, 134, 0.2) 0px 0px 15px, rgba(101, 119, 134, 0.15) 0px 0px 3px 1px;
  position: fixed;
  background-color: #f9f9f9;
  bottom: 0;
  width: 430px;
  border-radius: 16px;
  padding: 20px;
  height: 550px;
  padding-bottom: 40px;
  margin-bottom: -20px;
  overflow: hidden;

}

.userMessgaesDiv.clicked {
  background: white;
  padding: 15px;
  width: 111%;
  margin: -20px;
  padding-bottom: 0px;
}

.close svg {
  font-size: 30px;
  float: revert;
  position: absolute;
  right: 0;
  margin-right: 24px;
}

.clicked p#time {
  width: 100%;
  float: left;
  margin-left: 53px;
}

.messagePopup button.accordion-button.collapsed {
  position: fixed;
  background-color: white;
  width: 400px;
  right: 0;
  padding: 20px;
  bottom: 0;
  border-radius: 16px;
  margin-bottom: -20px;
  padding-top: 15px;
  box-shadow: rgba(101, 119, 134, 0.2) 0px 0px 15px, rgba(101, 119, 134, 0.15) 0px 0px 3px 1px;
  margin-right: 10px;
  cursor: pointer;
  z-index: 99999;
}

.messagePopup button.accordion-button {
  margin-bottom: -25px;
}


.messagePopup .accordion-item,
.messagePopup .accordion-header {
  border: none;
}

.messagePopup .accordion-button::after {
  margin-top: -11px;
}

.messagePopup p {
  width: 80%;
  float: left;
  font-size: 22px;
  font-family: 'bl-bold';
}

.messagePopup .accordion-button:not(.collapsed)::after {
  margin-top: -19px;
  margin-left: 40px;
  color: black !important;
}

#messageIcon {
  font-size: 22px;
  margin-top: -15px;
}

.userMessgaesDiv {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}

.userMessgaesDiv img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  float: left;
}

.userMessgaesDiv p {
  float: left;
  margin-left: 10px;
  font-family: bl-medium;
  font-size: 15px;
  margin-top: 8px;
}

#time {
  font-size: 12px;
  float: left;
  opacity: .6;
  margin-top: -20px;
}

.messagePart {
  float: left;
  margin-top: 40px;
  width: 100%;
}

.userMessage p {
  background: linear-gradient(0deg, #FF2A68 0%, #FF5E3A 100%);
  border-radius: 10px 10px 10px 0px;
  color: white;
  font-family: bl-medium;
  height: auto;
  width: auto;
  float: left;
  padding: 10px;
}

.managerMessage,
.userMessage {
  width: 100%;
  float: left;
}

.managerMessage p {
  background: white;
  border-radius: 10px 10px 0px 10px;
  width: auto;
  float: right;
  padding: 10px;
  margin-top: 0px;
  font-family: bl-medium;
}

.inputEmail {
  height: 80px;
  width: 100%;
  float: left;
  position: absolute;
  bottom: 0;
  margin: -20px;
  margin-bottom: 20px;
  padding: 20px;
}

#mailSend {
  background-color: white;
  padding: 20px;
  border: none;
  width: 80%;
  height: 90px;
  margin: -20px;
  border-radius: 0px;
  float: left;

}

#mailSendBtn {
  float: right;
  width: 27%;
  margin-bottom: 9000px;
  z-index: 9999;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 80px;
  margin-bottom: 0px;
  padding: 15px;
  background: linear-gradient(0deg, #FF2A68 0%, #FF5E3A 100%);
  border-radius: 0px;
  border: none;
}


/* remove in future disable function */
#mailSend,
.inputEmail {
  float: left;
  padding: 20px;
  pointer-events: none;
  opacity: 0.7;
}

.resend button {
  background-color: transparent;
  color: black;
  border: none;
  margin-bottom: -20px;
  margin-left: -12px;
}

.resend button:hover {
  background-color: transparent !important;
  color: black !important;
  border: none !important;
  margin-left: -12px;
}

.resend .btn:disabled {
  background-color: transparent !important;
  color: #717171 !important;
  border: none !important;
  margin-left: -12px;
  margin-top: -32px;
}

@media (max-width: 1200px) {
  .cp-section-div1 {
    margin-bottom: 200px;
  }
}


.all_section {
  float: left;
  width: 100%;
}

.reset .btn-close {
  margin-top: -10%;
}

.coupon_card button {
  height: 30%;
  /* border: none; */
  /* outline: #891e1e; */
  /* background-color:#FF5A5F ; */
}

.coupon_card{
  display: flex !important;
}

.pay_btn{
  height: 30%;
    border: none;
    background-color:#FF5A5F !important ;
}

.pay_btn:hover{
    height: 30%;
    border: none;
    background-color: #FF5A5F !important;
}

#validate_coupon_input{
  height: 43%;
}

#coupon_err_msg{
  font-size: 14px;
}