@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* for laoder */

.loader {
  border: 16px solid #D4D4D4;
  border-radius: 50%;
  border-top: 16px solid #E82112;
  width: 100px;
  background-color: transparent;
  height: 100px;
  position: fixed;
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 99999999;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}